import {useState} from 'react';
import {BsArrowReturnRight} from 'react-icons/bs';
import ThreadComment from './thread-comment';
import ThreadReply from './thread-reply';

const ThreadMessageGroup = ({
  contentId,
  messageIds,
  threadContent,
  onReply,
  onQuote,
  focusedContent,
  sub,
  setFocusedContent,
}) => {
  const [hide, setHide] = useState(false);
  const [all, setAll] = useState(
    messageIds?.includes(focusedContent) ? true : false,
  );

  return (
    <div className="message-group">
      <ThreadComment
        item={threadContent?.[contentId] ?? {}}
        onReply={e => {
          e.stopPropagation();
          onReply(prev => (prev ? null : contentId));
        }}
        focused={contentId === focusedContent}
        onClick={() => {
          if (messageIds?.length) {
            setHide(!hide);
          }
        }}
        sub={sub}
        setFocusedContent={setFocusedContent}
      />
      {hide ? (
        <div className="flex-row align-center" style={{marginLeft: '68px'}}>
          <BsArrowReturnRight className="text-secondary" fontSize={'18px'} />
          <p className="text-secondary text-12 margin-h8">
            {messageIds?.length} replies
          </p>
        </div>
      ) : (
        <div className="nested-messages">
          {messageIds?.slice(0, all ? messageIds?.length : 3).map(nestedId => {
            return (
              <ThreadReply
                key={nestedId}
                item={threadContent?.[nestedId] ?? {}}
                onReply={() => onReply(prev => (prev ? null : contentId))}
                onQuote={onQuote}
                focused={nestedId === focusedContent}
                sub={sub}
                setFocusedContent={setFocusedContent}
              />
            );
          })}
          {messageIds?.length > 3 && !all ? (
            <p
              className="text-link clickable"
              style={{marginLeft: '68px'}}
              onClick={() => {
                setAll(true);
              }}>
              See all
            </p>
          ) : null}
        </div>
      )}
    </div>
  );
};
export default ThreadMessageGroup;
