/* eslint-disable import/no-anonymous-default-export */
import CryptoJS from 'crypto-js';

export default () => {
  const crypto_key = process.env.REACT_APP_SALT;

  const encryptString = string => {
    try {
      if (!string || !crypto_key) {
        return null;
      }
      const encrypted = CryptoJS.AES.encrypt(string, crypto_key).toString();
      // Convert to Base64 and make URL-safe
      return btoa(encrypted)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const decryptString = string => {
    try {
      if (!string || !crypto_key) {
        return null;
      }
      // Restore Base64 string
      const restored = atob(string.replace(/-/g, '+').replace(/_/g, '/'));
      const bytes = CryptoJS.AES.decrypt(restored, crypto_key);
      const decoded = bytes.toString(CryptoJS.enc.Utf8);
      return decoded;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  return {encryptString, decryptString};
};
