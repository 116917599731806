import {useFormik} from 'formik';
import React, {useEffect} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import * as Yup from 'yup';
import '../../App.css';
import {track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import tagsJSON from '../../assets/lists/tags.json';
import {PrimaryButton} from '../../components/buttons/buttons';
import constants from '../../components/constants';
import {
  FormikPlacesAutocomplete,
  FormikSearchableDropDown,
} from '../../components/inputs/drop-down';
import {
  FormikTextfield,
  MultilineFormikTextfield,
} from '../../components/inputs/textfields';
import {AuthHeader} from '../../components/layout/layout';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {useAuth} from '../../hooks/use-auth';
import {useMounted} from '../../hooks/use-mounted';

const CreateOrganization = ({}) => {
  const navigate = useNavigate();
  const isMounted = useMounted();

  const {
    state: {id, profile, current_organization, organizations, referral},
    updateOrganization,
    updateProfile,
    getReferral,
  } = useAuth();

  const mobile = isMobile || isTablet;
  const organization = organizations?.[current_organization] ?? {};

  useEffect(() => {
    if (!referral) {
      getReferral(id);
    }
  }, []);

  const URLFormatter = value => {
    if (!value) return '';

    if (!value.startsWith('http://') && !value.startsWith('https://')) {
      return `https://${value}`;
    }
    return value;
  };

  const formik = useFormik({
    initialValues: {
      name: organization?.name ?? '',
      website: organization?.website ?? '',
      address: organization?.address ?? '',
      bio: organization?.bio ?? '',
      specific_type: organization?.specific_type ?? '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required.').trim(),
      website: Yup.string()
        .trim()
        .matches(/^(https?:\/\/)?([\w\d-]+\.)+\/?/, 'Please enter a valid URL'),
      address: Yup.string().trim(),
      bio: Yup.string().trim(),
      specific_type: Yup.string().required('Type is a required field.'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {name, website, address, bio, specific_type} = values;
        const query_name = name
          .replace(constants.remove_special_chars_regex, '')
          .toUpperCase();
        const org_type = tagsJSON.find(item => item.key === specific_type);
        const type = org_type?.sub_type ?? null;

        const {
          id: org_id,
          members,
          expiration,
          subscription,
          status: cur_status,
        } = organization;

        const status = 'complete';
        const current_members = [...(members || [])];

        if (!current_members.includes(id)) {
          current_members.push(id);
        }

        const now = dateToTimestamp();
        const update = {
          id: org_id || v4(),
          status,
          name,
          query_name,
          website: URLFormatter(website),
          address,
          bio,
          type,
          specific_type,
          members: current_members,
          last_seen: now,
        };

        const {success, error, data} = await updateOrganization(update, true);

        if (success && isMounted()) {
          const {
            org: {id: org_id},
          } = data;

          // Check if the organization is not already in the profile
          if (
            profile &&
            (!profile?.organization_ids?.includes(org_id) ?? false)
          ) {
            const updated_prof = {
              id,
              organization_ids: [...(profile?.organization_ids ?? []), org_id],
            };
            await updateProfile(updated_prof);
          }

          track('create_organization', update);
          if (cur_status !== 'complete') {
            track('new_organization', {
              name: organization.name,
              org_id: current_organization,
            });
          }

          navigate('/home');
        }
        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        if (isMounted()) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <>
      <div className="container">
        <br />
        <AuthHeader
          header="Create Organization"
          subheader="Tell others about your organization."
        />
        <div className="flex justify-center">
          <form
            className={
              mobile
                ? 'mobile-form pretty-form-group'
                : 'grid-6 pretty-form-group'
            }>
            <div className="grid-container">
              <div className="grid-6">
                <div className="padding-h8">
                  <FormikTextfield
                    formik={formik}
                    header={'Organization Name'}
                    placeholder=""
                    name="name"
                    type="text"
                  />
                  <FormikTextfield
                    formik={formik}
                    header="Organization Website"
                    placeholder="https://example.com"
                    name="website"
                    type="text"
                  />
                </div>
              </div>
              <div className="grid-6">
                <div className="padding-h8">
                  <FormikSearchableDropDown
                    formik={formik}
                    header="Type of Organization"
                    name="specific_type"
                    items={tagsJSON.filter(
                      item => item.type === 'collaborator',
                    )}
                    mappings={{key: 'pretty', value: 'key'}}
                  />
                  <FormikPlacesAutocomplete
                    formik={formik}
                    header="Primary Location"
                    placeholder="New York, New York"
                    name="address"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="padding-h8">
              <MultilineFormikTextfield
                formik={formik}
                header="Description of your organization?"
                placeholder="We're an amazing organization dedicated to..."
                name="bio"
                type="text"
              />
            </div>

            {formik.errors.submit && (
              <p className="error">{formik.errors.submit}</p>
            )}
          </form>
        </div>
      </div>
      <div className="flex justify-center padding-top16">
        {formik.isSubmitting ? (
          <SpinningIndicator />
        ) : (
          <PrimaryButton
            type="button"
            className="button-container"
            disabled={formik.isSubmitting}
            onClick={handleSubmit}>
            CONTINUE
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

export default CreateOrganization;
