import constants from '../../components/constants';

const generatePlatformInvite = props => {
  const {link = null, profile, note} = props || {};

  const {email, first_name, last_name} = profile || {};
  const pretty_name =
    first_name || last_name
      ? `${first_name || ''} ${last_name || ''}`.trim()
      : email;

  return `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap" rel="stylesheet">
    <style>
      body { font-family: 'Inter', sans-serif; color: #333; line-height: 1.6; margin: 0; padding: 0; background-color: #f7f7f7; }
      .container { max-width: 600px; margin: 0 auto; padding: 20px; background-color: #ffffff; }
      .logo { text-align: center; margin-bottom: 20px; }
      .content { background-color: #f4f4f4; padding: 24px; border-radius: 8px; }
      .button { display: inline-block; background-color: #4743c5; color: white; padding: 12px 24px; text-decoration: none; border-radius: 4px; font-weight: 600; }
      .secondary-button { display: inline-block; background-color: #ffffff; color: #333; padding: 10px 20px; text-decoration: none; border-radius: 4px; font-weight: 600; border: 1px solid #333; }
      .footer { text-align: center; margin-top: 20px; color: #666; font-size: 14px; }
      .footer a { margin: 0 8px; color: #666; text-decoration: none; }
      .note { white-space: pre-line; border: 1px solid #ccc; padding: 16px; border-radius: 8px; margin-bottom: 20px; }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="logo">
        <img src="${
          constants.publicmind_logo
        }" alt="PublicMind Logo" height="48" width="48" />
        <h2 style="font-weight: 600; margin-top: 12px;">PUBLICMIND</h2>
      </div>
      <div class="content">
        <h3 style="text-align: center; margin-bottom: 24px;">You've been invited to join PublicMind!</h3>
        ${note ? `<div class="note">${note}</div>` : ''}
        <p>${pretty_name} has invited you to join PublicMind, a platform that simplifies communication and collaboration between government, academia, and industry.</p>
        <p><strong>Why join PublicMind?</strong></p>
        <ul>
          <li>Effortlessly connect with stakeholders across sectors</li>
          <li>Streamline your collaboration process</li>
          <li>Access a network of professionals in your field</li>
        </ul>
        <p>Ready to get started? Click the button below to accept the invitation and begin collaborating:</p>
        <div style="text-align: center; margin: 24px 0;">
          <a href="${link}" class="button">Join PublicMind</a>
        </div>
        <p>Want to learn more before joining? Schedule a quick chat with our team:</p>
        <div style="text-align: center; margin: 24px 0;">
          <a href="https://calendly.com/jack-publicmind" class="secondary-button">Schedule a Call</a>
        </div>
        <p>If you have any questions, feel free to reply to this email. We're here to help!</p>
        <p>Best regards,<br>The PublicMind Team</p>
      </div>
      <div class="footer">
        <p>Sent from PublicMind</p>
        <div>
          <a href=${constants.copyright_policy}">Copyright Policy</a>
          <a href="${constants.privacy_policy}">Privacy Policy</a>
          <a href="${constants.terms_of_service}">Terms of Service</a>
        </div>
      </div>
    </div>
  </body>
  </html>
  `;
};

export default generatePlatformInvite;
