import React, {lazy, Suspense, useEffect, useState} from 'react';
import {RxBell, RxChevronRight, RxInfoCircled} from 'react-icons/rx';
import {useNavigate, useParams} from 'react-router-dom';
import {timeSince} from '../../api/dates';
import constants from '../../components/constants';
import ThreadButtons from '../../components/inputs/ThreadButtons';
import {FileViewerSimple, ProfileImage} from '../../components/inputs/uploader';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import MagicMarkdown from '../../components/markdown/magic-markdown';
import {TooltipRight} from '../../components/tooltip/tooltip';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import useCrypto from '../../hooks/use-crypto';
import useLoader from '../../hooks/use-loader';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import IntegratedAIChatPopup from './AIChat';
import './ThreadStyles.css';
import ThreadList from './thread-list';

const ContentDrafts = lazy(() => import('./content-drafts'));
const ThreadFeed = lazy(() => import('./thread-feed'));
const ThreadSearch = lazy(() => import('./thread-search'));
const TasksTab = lazy(() => import('./tasks'));
const SinceLastTime = lazy(() => import('./since-last-time'));
const ThreadDetail = lazy(() => import('./thread-detail'));

const ThreadsOverview = () => {
  const {thread_id, content_id} = useParams();
  const navigate = useNavigate();
  const {threadPermissions} = useCollaborator();
  const {
    state: {id: userId, groups},
  } = useAuth();
  const {
    state: {
      thread_ids,
      threads,
      threads_loaded,
      thread_subs,
      subscriptions,
      thread_content,
    },
    getThreads,
    getThreadSubscriptions,
    attachThreadContentListener,
    attachThreadListener,
    getContents,
  } = useThreads();
  const [current, setCurrent] = useState(thread_id ? thread_id : null);
  const [focusedContent, setContent] = useState(content_id || null);
  const [index, setIndex] = useState(thread_id ? 2 : 'feed');
  const [loading, setLoading] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);

  const [isAIChatOpen, setIsAIChatOpen] = useState(false);
  const [TOTAL_PAID_THREADS, setPaidThreads] = useState(null);
  const [searched_ids, setSearched] = useState([]);
  const [search, setSearch] = useState('');
  const thread = threads?.[current] ?? {};

  // CALC PAID THREAD COUNT
  useEffect(() => {
    if (threads && thread_ids) {
      const ownedThreadsCount = thread_ids.filter(threadId => {
        const thread = threads[threadId];
        return (
          thread.permissions.find(p => p.user_id === userId)?.role === 'owner'
        );
      }).length;
      setPaidThreads(ownedThreadsCount);
    }
  }, [threads, thread_ids, userId]);

  //GET THREADS AND SUBs
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const query = {
        filter: {
          members: {contains: userId},
          status: {eq: 'active'},
        },
        limit: constants.all_items,
      };
      attachThreadListener(query);
      const {success, data} = await getThreads(query);
      await getThreadSubscriptions({
        user_id: userId,
        limit: constants.all_items,
      });
      setLoading(false);
      setLoadingContent(true);
      if (success && data?.length) {
        if (!thread_id && index !== 'feed') {
          setCurrent(data[0]);
        }

        // LOAD ALL CONTENTS FOR THREADS
        await Promise.all(
          data.map(thread_id => {
            const query = {
              thread_id,
              filter: {status: {eq: 'active'}},
              limit: constants.all_items,
            };
            return getContents(query, thread_id);
          }),
        );
      }
      setLoadingContent(false);
    };
    // LOAD IN DATA
    if (!threads_loaded) {
      load();
    }
  }, []);

  useEffect(() => {
    if (thread_id) {
      setCurrent(thread_id);
    }
  }, [thread_id]);
  useEffect(() => {
    if (content_id) {
      setContent(content_id);
    }
  }, [content_id]);
  // ATTACH LISTENERS
  useEffect(() => {
    if (!thread_ids?.length) {
      return;
    }

    thread_ids.forEach(thread_id => {
      if (subscriptions[thread_id] === undefined) {
        attachThreadContentListener(
          {filter: {thread_id: {eq: thread_id}, status: {eq: 'active'}}},
          thread_id,
          userId,
        );
      }
    });
  }, [thread_ids]);

  useEffect(() => {
    if (search) {
      const keys = Object.keys(thread_content);
      const filtered = keys.filter(content_id => {
        const {content} = thread_content?.[content_id] ?? {};

        return content?.includes(search);
      });

      setSearched(filtered);
    } else {
      setContent([]);
    }
  }, [search]);

  useEffect(() => {
    if (index !== 2) {
      setCurrent(null);
      navigate('/threads');
    }
  }, [index]);

  const isProUser = () => {
    return (
      userId === 'jack@publicmind.ai' ||
      userId === 'charlie@publicmind.ai' ||
      userId === 'kirtan@publicmind.ai' ||
      userId === 'shsimmons@dew.sc.gov' ||
      userId === 'andy.bernardin@sc.edu'
    );
  };

  return (
    <div className="page-container">
      <div className="threads-overview">
        <div className="threads-container">
          <div className="threads-list">
            <div className="threads-header">
              <TooltipRight
                text={'Threads are secure workspaces for your projects'}>
                <span className="flex-row">
                  <h4>Threads</h4>
                  <RxInfoCircled />
                </span>
              </TooltipRight>

              <button
                className="add-thread-button"
                disabled={!isProUser && TOTAL_PAID_THREADS >= 3}
                onClick={() => navigate('/threads/create')}>
                <TooltipRight
                  text={
                    isProUser()
                      ? `${TOTAL_PAID_THREADS} out of Unlimited Threads Used`
                      : `${TOTAL_PAID_THREADS} out of 3 Free Threads Used`
                  }>
                  <p className="text-secondary text-10">New Thread</p>
                </TooltipRight>
              </button>
            </div>
            <div className="mention-menu-group">
              <ThreadButtons
                index={index}
                setIndex={setIndex}
                setIsAIChatOpen={setIsAIChatOpen}
                isAIChatOpen={isAIChatOpen}
              />
            </div>
            <div className="thread-items">
              {loading ? (
                <SpinningIndicator />
              ) : (
                <ThreadList
                  current={current}
                  setCurrent={setCurrent}
                  setIndex={setIndex}
                />
              )}
            </div>
          </div>
          <div className="thread-detail">
            <Suspense fallback={<SpinningIndicator />}>
              {index === 'feed' && (
                <ThreadFeed
                  setCurrent={setCurrent}
                  setIndex={setIndex}
                  setContent={setContent}
                />
              )}
              {index === 'activity' && (
                <SinceLastTime
                  setCurrent={setCurrent}
                  setIndex={setIndex}
                  setContent={setContent}
                />
              )}
              {index === 'tasks' && (
                <TasksTab
                  setCurrent={setCurrent}
                  setIndex={setIndex}
                  setContent={setContent}
                />
              )}
              {index === 'mentions' && (
                <Mentions
                  setCurrent={setCurrent}
                  setIndex={setIndex}
                  setContent={setContent}
                />
              )}
              {index === 'search' && (
                <ThreadSearch
                  setCurrent={setCurrent}
                  setIndex={setIndex}
                  setContent={setContent}
                  content_ids={searched_ids}
                />
              )}
              {index === 'drafts' && (
                <ContentDrafts setIndex={setIndex} setCurrent={setCurrent} />
              )}
              {index === 2 && (
                <ThreadDetail
                  id={current}
                  focused_content={focusedContent}
                  setFocusedContent={setContent}
                  setCurrent={setCurrent}
                />
              )}
            </Suspense>
          </div>
        </div>
      </div>

      {isAIChatOpen && (
        <IntegratedAIChatPopup
          thread={thread}
          onClose={() => setIsAIChatOpen(false)}
        />
      )}
    </div>
  );
};

const Mentions = ({setCurrent, setIndex, setContent}) => {
  const {
    state: {id},
  } = useAuth();
  const {
    state: {thread_content, threads},
  } = useThreads();
  const [mentionIds, setMentionIds] = useState([]);

  useEffect(() => {
    const keys = Object.keys(thread_content);
    const mentioned = keys
      .filter(content_id => {
        const {mentions} = thread_content?.[content_id] ?? {};
        return mentions?.includes(id);
      })
      .sort((a, b) => {
        const a_content = thread_content?.[a] ?? {};
        const b_content = thread_content?.[b] ?? {};
        return b_content?.created - a_content.created;
      });
    setMentionIds(mentioned);
  }, [thread_content, id]);

  return (
    <div className="mentions-container">
      <div className="since-last-time-header">
        <h3 className="since-last-time-title">Your Mentions</h3>
        <p className="text-secondary">
          Chronological list of all mentions across all threads appear here
        </p>
      </div>
      {mentionIds.length ? (
        <div className="mentions-list">
          {mentionIds.map(content_id => (
            <MentionItem
              key={content_id}
              item={thread_content[content_id]}
              thread={threads[thread_content[content_id].thread_id]}
              onClick={() => {
                setIndex(2);
                setCurrent(thread_content[content_id].thread_id);
                setContent(content_id);
              }}
            />
          ))}
        </div>
      ) : (
        <div className="mentions-empty">
          <RxBell className="mentions-empty-icon" />
          <p>No mentions yet</p>
          <p>When someone mentions you in a thread, you'll see it here.</p>
        </div>
      )}
    </div>
  );
};

export const MentionItem = ({item, thread, onClick}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();
  const {loadProfiles} = useLoader();
  const {encryptString} = useCrypto();

  const {
    state: {profiles},
  } = useAuth();
  const {owner_id, content, created, media} = item || {};
  const user = profiles?.[owner_id] ?? {};
  const profile_image = user?.profile_image;
  const {title: threadTitle, logo} = thread || {};

  useEffect(() => {
    if (owner_id) {
      loadProfiles([owner_id]);
    }
  }, []);

  return (
    <div className="mention-item">
      <div className="mention-header">
        <ProfileImage
          data={profile_image}
          style={{height: '40px', width: '40px'}}
          onClick={() => {
            const parsed = encryptString(owner_id);
            navigate(`/feed/profiles/detail/${parsed}`);
          }}
        />
        <div className="mention-info">
          <h3 className="mention-thread-title">
            {prettyName(user)} <span className="text-secondary">from</span>{' '}
            {logo} {threadTitle}
          </h3>
          <p className="mention-time">{timeSince(created)}</p>
        </div>
      </div>
      <div className="content-body">
        <MagicMarkdown content={content} />
        {media?.length > 0 && <FileViewerSimple files={media} />}
      </div>
      <div className="mention-footer">
        <span className="mention-view" onClick={onClick}>
          View thread
        </span>
        <RxChevronRight className="mention-arrow" />
      </div>
    </div>
  );
};

export default ThreadsOverview;
