import {useCallback, useState} from 'react';
import {RxCopy, RxPencil1, RxTrash} from 'react-icons/rx';
import {EmojiInput} from '../../components/inputs/emoji-input';
import {EditCommentReply} from '../../components/inputs/reply-to-comment';
import {
  BreakoutThreadModal,
  EditTaskModal,
} from '../../components/modal/thread-modals';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import {useThreads} from '../../hooks/use-threads';
import useUtilities from '../../hooks/use-utilities';

export const ContentActions = ({item, onQuote, onReply}) => {
  const {id, thread_id, content, parent, owner_id, reactions, type, mentions} =
    item;
  const {threadActions} = useCollaborator();
  const {
    state: {threads},
    deleteThreadContent,
    updateContent,
  } = useThreads();
  const {
    state: {id: user_id, profiles},
  } = useAuth();

  const user = profiles?.[owner_id] ?? {};
  const [showEmoji, setShowEmoji] = useState(false);
  const [breakout, setBreakout] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editTask, setEditTask] = useState(false);

  const {first_name, last_name, position} = user;

  const thread = threads?.[thread_id] ?? {};
  const {editable, writeable} = threadActions(thread);

  const owner = owner_id === user_id;

  const assigned = type === 'task' && mentions?.includes(user_id);

  const {copyString} = useUtilities();

  const handleCopy = useCallback(() => {
    copyString(content);
  }, [content, copyString]);

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this reply?')) {
      console.log('Delete content:', item.id);
      deleteThreadContent({id: item.id});
    }
  };
  const handleShare = () => {
    console.log('Share content:', item.id);
  };

  return (
    <>
      <div className="content-actions">
        {writeable && (
          <>
            {!parent && (
              <button className="action-button" type="submit" onClick={onReply}>
                Reply
              </button>
            )}
            {parent && (
              <button
                className="action-button"
                type="submit"
                onClick={() =>
                  onQuote(
                    item.parent,
                    `@[${first_name} ${last_name}](/feed/profiles/detail/${owner_id}) `,
                  )
                }>
                Reply
              </button>
            )}
          </>
        )}
        {writeable && (
          <button
            className="action-button"
            type="submit"
            onClick={() => {
              setBreakout(true);
            }}>
            Break out
          </button>
        )}

        <EmojiInput
          setEmoji={async emoji => {
            const cleaned = [...(reactions || [])].filter(
              reaction => reaction.user_id !== user_id,
            );
            cleaned.push({reaction: emoji, user_id});
            await updateContent({id, reactions: cleaned});
          }}
          setShowPicker={setShowEmoji}
          showPicker={showEmoji}
        />
        {/* <button className="action-button" onClick={handleShare}>
        <RxShare1 />
        </button> */}
        <button className="action-button" onClick={handleCopy}>
          <RxCopy />
        </button>

        {(owner || assigned) && (
          <>
            <button
              className="action-button"
              onClick={e => {
                e.stopPropagation();
                if (type === 'task') {
                  setEditTask(!editTask);
                } else {
                  setEdit(!edit);
                }
              }}>
              <RxPencil1 />
            </button>
          </>
        )}
        {owner && (
          <button className="action-button" onClick={handleDelete}>
            <RxTrash />
          </button>
        )}
        {breakout && (
          <BreakoutThreadModal
            active={breakout}
            setActive={setBreakout}
            content={item}
          />
        )}
      </div>
      {edit && (
        <EditCommentReply
          comment={item}
          onClose={() => {
            setEdit(!edit);
          }}
          isOpen={edit}
        />
      )}
      {editTask && (
        <EditTaskModal setActive={setEditTask} active={editTask} task={item} />
      )}
    </>
  );
};
