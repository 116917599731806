import tagsJSON from '../assets/schemas/tags-schema.json';
import {useAuth} from './use-auth';

/* eslint-disable import/no-anonymous-default-export */
export default () => {
  const {
    state: {id, profile},
  } = useAuth();

  const threadPermissions = thread => {
    const {permissions} = thread;
    if (!permissions?.length) {
      return null;
    }
    const current = permissions?.find(item => item.user_id === id);
    return current;
  };

  const threadActions = thread => {
    const {permissions} = thread;
    if (!permissions?.length) {
      return {editable: false, writeable: false};
    }
    const current = threadPermissions(thread);

    if (!current) {
      return {editable: false, writeable: false};
    }
    switch (current.role) {
      case 'owner':
      case 'admin':
        return {editable: true, writeable: true};
      case 'collaborator':
        return {editable: false, writeable: true};
      default:
        return {editable: false, writeable: false};
    }
  };

  const sortTags = (inner, outer) => {
    const {inner_tags, outer_tags} = profile || {};
    // if (!inner?.length || !outer?.length) {
    //   return {similar: [], excluded: [], sorted: []};
    // }

    // if (!inner_tags?.length || !outer_tags?.length) {
    //   return {similar: [], excluded: array, sorted: array};
    // }

    const inner_similar = [];
    const inner_excluded = [];
    const outer_similar = [];
    const outer_excluded = [];
    inner?.forEach(tag => {
      if (outer_tags?.includes(tag) && !inner_similar?.includes(tag)) {
        inner_similar.push(tag);
      } else {
        if (!inner_excluded?.includes(tag)) {
          inner_excluded.push(tag);
        }
      }
    });

    outer?.forEach(tag => {
      if (inner_tags?.includes(tag) && !outer_similar?.includes(tag)) {
        outer_similar.push(tag);
      } else {
        if (!outer_excluded?.includes(tag)) {
          outer_excluded.push(tag);
        }
      }
    });

    return {
      inner_similar,
      inner_excluded,
      inner_sorted: [...inner_similar, ...inner_excluded],
      outer_similar,
      outer_excluded,
      outer_sorted: [...outer_similar, ...outer_excluded],
    };
  };

  const compareUpdatedTags = (inner, outer) => {
    const {inner_tags, outer_tags} = profile;

    const {added: inner_added, removed: inner_removed} = tagDifferences(
      inner_tags,
      inner,
    );
    const {added: outer_added, removed: outer_removed} = tagDifferences(
      outer_tags,
      outer,
    );

    return {inner_added, inner_removed, outer_added, outer_removed};
  };

  const tagDifferences = (start, end) => {
    try {
      const added = [];
      const removed = [];
      // Find removed items
      start.forEach(tag => {
        if (!end.includes(tag)) {
          removed.push(tag);
        }
      });

      // Find added items
      end.forEach(tag => {
        if (!start.includes(tag)) {
          added.push(tag);
        }
      });
      return {added, removed};
    } catch (err) {
      return {added: [], removed: []};
    }
  };

  const tagToObject = tag => {
    const value = tagsJSON[tag];

    if (value) {
      return value;
    }
    return null;
  };

  return {
    threadPermissions,
    threadActions,
    sortTags,
    tagToObject,
    compareUpdatedTags,
    tagDifferences,
  };
};
