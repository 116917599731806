import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {timeSince} from '../../api/dates';
import {FileViewerSimple, ProfileImage} from '../../components/inputs/uploader';
import DeletedContent from '../../components/item-details/deleted-content';
import MagicMarkdown from '../../components/markdown/magic-markdown';
import {useAuth} from '../../hooks/use-auth';
import useCrypto from '../../hooks/use-crypto';
import useLoader from '../../hooks/use-loader';
import {useThreads} from '../../hooks/use-threads';
import {ContentActions} from './content-actions';

const ThreadReply = ({
  item,
  onReply,
  onQuote,
  focused,
  sub,
  setFocusedContent,
}) => {
  const {loadProfiles} = useLoader();
  const {encryptString} = useCrypto();
  const {updateContent} = useThreads();

  const navigate = useNavigate();

  const {
    state: {id, profiles, organizations},
  } = useAuth();

  const contentRef = useRef(null);
  const {content, parent, owner_id, thread_id, created, media, reactions} =
    item;

  const [edit, setEdit] = useState(false);
  const [reactionSum, setReactions] = useState([]);
  const [isTarget, setIsTarget] = useState(false);

  const new_content = owner_id !== id && (!sub || created > sub.last_read);

  const timeSinceString = timeSince(item?.updated);

  const user = profiles?.[owner_id] ?? {};
  const profile_image = user?.profile_image;
  const {first_name, last_name, position, organization_ids} = user;
  const current_org = organization_ids?.length
    ? organizations?.[organization_ids[0]]
    : null;

  useEffect(() => {
    if (!profiles?.[owner_id]) {
      loadProfiles([owner_id], {authMode: 'apiKey'});
    }
  }, [owner_id]);

  useEffect(() => {
    if (focused && contentRef.current) {
      contentRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
      setIsTarget(true);
      setFocusedContent(null);
    }
  }, [focused]);

  useEffect(() => {
    const sum = {};
    reactions?.forEach(item => {
      const {reaction, user_id} = item;
      if (sum[reaction] === undefined) {
        sum[reaction] = {count: 0, ids: []};
      }
      sum[reaction].count += 1;
      sum[reaction].ids.push(user_id);
    });
    const mapped = Object.keys(sum).map(key => {
      const values = sum[key];
      return {reaction: key, ...values};
    });
    setReactions(mapped);
  }, [reactions]);

  if (!item.id) {
    return <DeletedContent type="reply" />;
  }

  return (
    <div
      className={`content-item ${
        new_content ? 'new' : isTarget ? 'target' : ''
      }`}
      ref={contentRef}>
      <div className="content-header">
        <ProfileImage
          data={profile_image}
          style={{height: '40px', width: '40px'}}
          onClick={() => {
            const parsed = encryptString(owner_id);
            navigate(`/feed/profiles/detail/${parsed}`);
          }}
        />
        <div className="user-info">
          <span className="flex-column">
            <span className="flex-row">
              <h3 className="user-name">{`${first_name} ${last_name}`}</h3>
              {position && (
                <p className="content-org">
                  {position}
                  {current_org?.name ? `, ${current_org?.name}` : null}
                </p>
              )}
              <p className="content-time">{timeSinceString}</p>
            </span>
          </span>
        </div>
      </div>
      <div className="content-body">
        <MagicMarkdown content={content} />
        {media?.length > 0 && <FileViewerSimple files={media} />}
        <div className="flex-row-wrap">
          {reactionSum.map(rct => {
            const {reaction, count, ids} = rct;
            const included = ids.includes(id);
            return (
              <div
                onClick={async () => {
                  if (!included) {
                    return;
                  }
                  const removed = reactions.filter(item => item.user_id !== id);

                  await updateContent({id: item.id, reactions: removed});
                }}
                key={reaction}
                className={`reaction-bubble ${included ? 'active' : ''}`}>
                {reaction} {count}
              </div>
            );
          })}
        </div>
      </div>
      <ContentActions item={item} onQuote={onQuote} onReply={onReply} />
    </div>
  );
};

export default ThreadReply;
