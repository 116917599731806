/* eslint-disable import/no-anonymous-default-export */
import {createSearchParams, useSearchParams} from 'react-router-dom';
import constants from '../components/constants';
import {useAuth} from './use-auth';
import useCrypto from './use-crypto';

export default () => {
  const {
    state: {id},
  } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const {encryptString, decryptString} = useCrypto();

  const paramsToData = () => {
    try {
      const parsed = {};

      searchParams.forEach((value, key) => {
        const decrypted = decryptString(value);
        parsed[key] = decrypted;
      });
      return parsed;
    } catch (err) {
      return null;
    }
  };

  const dataToParams = data => {
    if (!data) {
      return '';
    }
    const params = {};
    Object.keys(data).forEach(key => {
      const value = data[key];
      const encrypted = encryptString(value);
      params[key] = encrypted;
    });

    return createSearchParams(params).toString();
  };

  const createReferralLink = email => {
    const data = {
      status: 'active',
      referral_user: id,
    };
    if (email) {
      data.id = email;
    }

    const hash = dataToParams(data);
    const link = `${constants.signin_url}?${hash}`;
    return link;
  };

  return {
    paramsToData,
    dataToParams,
    createReferralLink,
  };
};
