import React, {useEffect, useRef, useState} from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {FaCheck, FaChevronDown, FaChevronRight} from 'react-icons/fa';
import {TbLoader} from 'react-icons/tb';
import useStringFormatter from '../../hooks/use-string-formatter';
import {LinkButton} from '../buttons/buttons';
import './styles.css';

export const SortDropdown = ({currentSort, onSortChange}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const sortOptions = [
    {value: 'recent', label: 'Recent'},
    {value: 'active', label: 'Activity'},
    // {value: 'tasks', label: 'Tasks'},
    // {value: 'milestones', label: 'Milestones'},
  ];

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSortChange = sortValue => {
    onSortChange(sortValue);
    setIsOpen(false);
  };

  return (
    <div className="sort-dropdown" ref={dropdownRef}>
      <button
        className="sort-dropdown-toggle"
        onClick={() => setIsOpen(!isOpen)}>
        <span>{currentSort.label}</span>
        <FaChevronDown className={`chevron ${isOpen ? 'open' : ''}`} />
      </button>
      {isOpen && (
        <ul className="sort-dropdown-menu">
          {sortOptions.map(option => (
            <li
              key={option.value}
              className={`sort-dropdown-item ${
                currentSort.value === option.value ? 'active' : ''
              }`}
              onClick={() => handleSortChange(option)}>
              {option.label}
              {currentSort.value === option.value && (
                <FaCheck className="check-icon" />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const DropDown = ({items, children, chevron}) => {
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div
        className="flex-row align-center justify-center dropbtn cursor-pointer"
        onClick={() => setShow(!show)}>
        {children}
        {chevron &&
          (show ? (
            <FaChevronRight size="16px" />
          ) : (
            <FaChevronDown size="16px" />
          ))}
      </div>
      <div id="myDropdown" className={`dropdown-content ${show ? 'show' : ''}`}>
        {items &&
          items.map(item => {
            const {text, onClick} = item;
            return (
              <div
                className="flex-row"
                key={text}
                onClick={async () => {
                  await onClick();
                  setShow(false);
                }}>
                <p>{text}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const SearchableDropDown = ({
  start,
  item,
  setValue,
  items,
  filter,
  header,
  mappings,
  onClear,
}) => {
  const {prettyHeader} = useStringFormatter();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState(start || '');
  const [filtered, setFilt] = useState(items);
  const {key = 'key', value = 'value', subtext} = mappings || {};
  let blurTimeout = null;

  useEffect(() => {
    const current = items.find(i => i[value] === item);

    if (item && current[key]) {
      setSearch(current[key]);
    }
  }, [item]);

  useEffect(() => {
    const filt = items.filter(item => {
      if (!search) {
        return true;
      }
      if (filter) {
        return filter(item);
      }
      return item[key].toUpperCase().includes(search.toUpperCase());
    });
    setFilt(filt);
  }, [search]);

  const handleBlur = () => {
    blurTimeout = setTimeout(() => {
      setShow(false);
    }, 150);
  };

  const handleClick = (v, k) => {
    if (blurTimeout) {
      clearTimeout(blurTimeout);
    }
    setValue(v);
    setSearch(k);
    setShow(false);
  };

  return (
    <div className="dropdown">
      {header && <label>{header}</label>}
      <div className="input-wrapper">
        <input
          type="text"
          value={search}
          onChange={e => setSearch(e.target.value)}
          onFocus={() => setShow(true)}
          onBlur={handleBlur}
        />
        {search ? (
          <AiOutlineCloseCircle
            onClick={() => {
              setSearch('');
              setValue(null);
              if (onClear) {
                onClear();
              }
            }}
            size={'20px'}
            className="interactive-input-icon"
          />
        ) : (
          <FaChevronDown size={'12px'} className="input-icon" />
        )}
      </div>
      <div id="myDropdown" className={`dropdown-content ${show && 'show'}`}>
        {filtered &&
          filtered.map(item => {
            const k = item[key];
            const v = item[value];
            const sub = item[subtext];
            return (
              <div key={v} onClick={() => handleClick(v, k)}>
                <p>{k}</p>
                {subtext && sub && (
                  <p className="text-secondary text-12">{prettyHeader(sub)}</p>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const ArraySearchableDropDown = ({
  values,
  setValues,
  items,
  filter,
  header,
  mappings,
}) => {
  const {prettyHeader} = useStringFormatter();

  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [filtered, setFilt] = useState(items);
  const [pretty, setPretty] = useState({});
  const {key = 'key', value = 'value', subtext} = mappings || {};
  let blurTimeout = null;

  // MAKE OBJECT TO MAP PRETTY FIELDS
  useEffect(() => {
    const obj = {};
    items.forEach(item => {
      const pretty = item[key];
      const val = item[value];
      obj[val] = pretty;
    });
    setPretty(obj);
  }, []);

  useEffect(() => {
    const filt = items.filter(item => {
      if (!search) {
        return true;
      }
      if (filter) {
        return filter(item);
      }
      return item[key].toUpperCase().includes(search.toUpperCase());
    });
    setFilt(filt);
  }, [search]);

  const handleBlur = () => {
    blurTimeout = setTimeout(() => {
      setShow(false);
    }, 150);
  };

  const handleClick = (v, k) => {
    if (blurTimeout) {
      clearTimeout(blurTimeout);
    }
    if (!values.includes(v)) {
      setValues([...values, v]);
    }
    setSearch('');
    setShow(false);
  };

  return (
    <div className="dropdown">
      {header && <label>{header}</label>}
      <div className="input-wrapper">
        <input
          type="text"
          value={search}
          onChange={e => {
            setSearch(e.target.value);
          }}
          onFocus={() => {
            setShow(true);
          }}
          onBlur={handleBlur}
        />
        <FaChevronDown size={'12px'} className="input-icon" />
      </div>
      <div id="myDropdown" className={`dropdown-content ${show && 'show'}`}>
        {filtered &&
          filtered.map(item => {
            const k = item[key];
            const v = item[value];
            const sub = item[subtext];
            return (
              <div key={v} onClick={() => handleClick(v, k)}>
                <p>{k}</p>
                {subtext && sub && (
                  <p className="text-secondary text-12">{prettyHeader(sub)}</p>
                )}
              </div>
            );
          })}
      </div>
      <div className="flex-row-wrap">
        {values && values.length
          ? values.map((value, i) => {
              return (
                <ValueDisplay
                  key={value}
                  value={pretty[value] || value}
                  onClick={() => {
                    const removed = [...values];
                    removed.splice(i, 1);
                    setValues(removed);
                  }}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

export const FormikSearchableDropDown = ({
  formik,
  name,
  items,
  filter,
  header,
  onClear,
  mappings,
}) => {
  const {prettyHeader} = useStringFormatter();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [filtered, setFilt] = useState(items);
  const {key = 'key', value = 'value', subtext} = mappings || {};
  let blurTimeout = null;

  const {onBlur, onChange, value: values} = formik.getFieldProps(name) || {};

  useEffect(() => {
    const filt = items.filter(item => {
      if (!search) {
        return true;
      }
      if (filter) {
        return filter(item);
      }
      return item[key].toUpperCase().includes(search.toUpperCase());
    });
    setFilt(filt);
  }, [search]);

  const handleBlur = () => {
    blurTimeout = setTimeout(() => {
      setShow(false);
    }, 150);
  };

  const handleClick = (v, k) => {
    if (blurTimeout) {
      clearTimeout(blurTimeout);
    }
    formik.setFieldValue(name, v);
    setSearch(k);
    setShow(false);
  };

  // FIND THE INITIAL VALUE IF THERE IS ONE
  useEffect(() => {
    if (values) {
      const exists = items.find(item => item[value] === values);
      if (exists) {
        setSearch(exists[key]);
      }
    }
  }, []);

  return (
    <div className="dropdown">
      {header && <label>{header}</label>}
      <div className="input-wrapper">
        <input
          type="text"
          value={search}
          onChange={e => {
            setSearch(e.target.value);
          }}
          onFocus={() => {
            setShow(true);
          }}
          onBlur={handleBlur}
        />
        {search ? (
          <AiOutlineCloseCircle
            onClick={() => {
              setSearch('');
              formik.setFieldValue(name, '');
              if (onClear) {
                onClear();
              }
            }}
            size={'20px'}
            className="interactive-input-icon"
          />
        ) : (
          <FaChevronDown size={'12px'} className="input-icon" />
        )}
      </div>
      <div id="myDropdown" className={`dropdown-content ${show && 'show'}`}>
        {filtered &&
          filtered.map(item => {
            const k = item[key];
            const v = item[value];
            const sub = item[subtext];
            return (
              <div key={v} onClick={() => handleClick(v, k)}>
                <p>{k}</p>
                {subtext && sub && (
                  <p className="text-secondary text-12">{prettyHeader(sub)}</p>
                )}
              </div>
            );
          })}
      </div>
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};

export const FormikArraySearchableDropDown = ({
  formik,
  name,
  items,
  filter,
  header,
  mappings,
  disabled,
  toggle,
}) => {
  const {prettyHeader} = useStringFormatter();
  const {key = 'key', value = 'value', subtext} = mappings || {};

  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [filtered, setFilt] = useState(items);
  const [pretty, setPretty] = useState({});
  const [select, setSelect] = useState(false);
  let blurTimeout = null;

  const {onBlur, onChange, value: values} = formik.getFieldProps(name) || {};

  // MAKE OBJECT TO MAP PRETTY FIELDS
  useEffect(() => {
    const obj = {};
    items?.forEach(item => {
      const pretty = item[key];
      const val = item[value];
      obj[val] = pretty;
    });
    setPretty(obj);
  }, []);

  useEffect(() => {
    const filt = items?.filter(item => {
      if (!search) {
        return true;
      }
      if (filter) {
        return filter(item);
      }
      return item[key].toUpperCase().includes(search.toUpperCase());
    });
    setFilt(filt);
  }, [search]);

  const handleBlur = () => {
    blurTimeout = setTimeout(() => {
      setShow(false);
    }, 150);
  };

  const handleClick = (v, k) => {
    if (blurTimeout) {
      clearTimeout(blurTimeout);
    }
    if (!values?.includes(v)) {
      if (values) {
        formik.setFieldValue(name, [...values, v]);
      } else {
        formik.setFieldValue(name, [v]);
      }
    }
    setSearch('');
    setShow(false);
  };

  return (
    <div className="dropdown">
      <div className={toggle ? 'flex-row justify-between align-center' : ''}>
        {header && <label>{header}</label>}
        {toggle && (
          <LinkButton
            type="button"
            onClick={() => {
              if (select) {
                formik.setFieldValue(name, []);
              } else {
                const all = items.map(item => item[value]);
                formik.setFieldValue(name, all);
              }
              setSelect(!select);
            }}>
            {select ? 'deselect all' : 'select all'}
          </LinkButton>
        )}
      </div>
      <div className="input-wrapper">
        <input
          type="text"
          value={search}
          onChange={e => {
            setSearch(e.target.value);
          }}
          onFocus={() => {
            if (disabled) {
              return;
            }
            setShow(true);
          }}
          onBlur={handleBlur}
        />
        <FaChevronDown size={'12px'} className="input-icon" />
      </div>
      <div id="myDropdown" className={`dropdown-content ${show && 'show'}`}>
        {filtered &&
          filtered.map(item => {
            const k = item[key];
            const v = item[value];
            const sub = item[subtext];
            return (
              <div key={v} onClick={() => handleClick(v, k)}>
                <p>{k}</p>
                {subtext && sub && (
                  <p className="text-secondary text-12">{prettyHeader(sub)}</p>
                )}
              </div>
            );
          })}
      </div>
      <div className="flex-row-wrap">
        {values && values.length
          ? values.map((value, i) => {
              return (
                <ValueDisplay
                  key={value}
                  value={pretty[value] || value}
                  onClick={() => {
                    const removed = [...values];
                    removed.splice(i, 1);
                    formik.setFieldValue(name, removed);
                  }}
                />
              );
            })
          : null}
      </div>
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};
export const FormikAddableTextfield = ({
  formik,
  name,
  items,
  filter,
  header,
  mappings,
  disabled,
  toggle,
}) => {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [filtered, setFilt] = useState(items);
  const {key = 'key', value = 'value'} = mappings || {};
  const [pretty, setPretty] = useState({});
  const {onBlur, onChange, value: values} = formik.getFieldProps(name) || {};

  const dropdownRef = useRef(null);

  useEffect(() => {
    const obj = {};
    items.forEach(item => {
      item.items.forEach(i => {
        const pretty = i[key];
        const val = i[value];
        obj[val] = pretty;
      });
    });
    setPretty(obj);
  }, []);

  useEffect(() => {
    const mapped = [];
    items.forEach(item => {
      const filt = item.items.filter(i => {
        if (!search) {
          return true;
        }
        if (filter) {
          return filter(i);
        }
        return i[key].toUpperCase().includes(search.toUpperCase());
      });
      if (filt.length) {
        mapped.push({...item, items: filt});
      }
    });

    setFilt(mapped);
  }, [search]);

  useEffect(() => {
    // Add event listener to detect clicks outside of the dropdown
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = (v, k) => {
    if (!values.includes(v)) {
      formik.setFieldValue(name, [...values, v]);
    }
    setSearch('');
    setShow(false);
  };
  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default form submit action
      if (search.trim() !== '' && !values.includes(search)) {
        formik.setFieldValue(name, [...values, search]);
        setSearch('');
        setShow(false);
      }
    }
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className={toggle ? 'flex-row justify-between align-center' : ''}>
        {header && <label className="padding-bottom8">{header}</label>}
      </div>
      <div className="input-wrapper">
        <input
          type="text"
          value={search}
          onChange={e => setSearch(e.target.value)}
          onKeyDown={handleKeyPress}
          onFocus={() => {
            if (disabled) {
              return;
            }
            setShow(true);
          }}
        />
      </div>
      <div className="flex-row-wrap">
        {values && values.length
          ? values.map((value, i) => {
              return (
                <ValueDisplay
                  key={value}
                  value={pretty[value] || value}
                  onClick={() => {
                    const removed = [...values];
                    removed.splice(i, 1);
                    formik.setFieldValue(name, removed);
                  }}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

export const ValueDisplay = ({value, onClick}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <p
      className="value-display"
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      {hovered ? <AiOutlineCloseCircle /> : '\u2022'}
      {value}
    </p>
  );
};

export const FormikPlacesAutocomplete = props => {
  const places_key = process.env.REACT_APP_GOOGLE_PLACE_KEY;
  const {formik, name, header, onEnter, type = 'text', ...rest} = props;

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: places_key,
  });

  const [show, setShow] = useState(false);
  let blurTimeout = null;

  const handleBlur = () => {
    blurTimeout = setTimeout(() => {
      setShow(false);
    }, 150);
  };

  const handleClick = v => {
    if (blurTimeout) {
      clearTimeout(blurTimeout);
    }
    formik.setFieldValue(name, v);
    setShow(false);
  };

  if (!places_key) {
    return null;
  }

  return (
    <div className="dropdown">
      {header && <label>{header}</label>}
      <div className="input-wrapper">
        <input
          {...rest}
          type={type}
          {...formik.getFieldProps(name)}
          onChange={evt => {
            const val = evt.target.value;
            formik.setFieldValue(name, val);
            if (val && val.length > 2) {
              getPlacePredictions({input: val});
            }
          }}
          onFocus={() => {
            setShow(true);
          }}
          onBlur={handleBlur}
        />
        {isPlacePredictionsLoading && (
          <TbLoader size={'24px'} className="input-icon" />
        )}
      </div>
      <div id="myDropdown" className={`dropdown-content ${show && 'show'}`}>
        {placePredictions.map(item => {
          return (
            <PlacesItem key={item.place_id} item={item} onClick={handleClick} />
          );
        })}
      </div>
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};

const PlacesItem = ({item, onClick}) => {
  const {description} = item;
  return (
    <div
      onClick={() => {
        onClick(description);
      }}>
      <p>{description}</p>
    </div>
  );
};
