import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../hooks/use-auth';
import useCrypto from '../../hooks/use-crypto';
import useLoader from '../../hooks/use-loader';
import useStringFormatter from '../../hooks/use-string-formatter';
import {ProfileImage} from '../inputs/uploader';
import {SimpleTagDisplay} from '../text/display-fields';
import {StatusIcon} from '../widgets/widgets';
import './styles.css';

export const DetailedProfileItem = ({item}) => {
  const navigate = useNavigate();
  const {loadOrgs} = useLoader();
  const {prettyName} = useStringFormatter();
  const {encryptString} = useCrypto();

  const {
    id,
    first_name,
    last_name,
    inner_tags,
    outer_tags,
    organization_ids,
    status,
    email,
    position,
    profile_image,
  } = item;

  const {
    state: {organizations},
  } = useAuth();

  useEffect(() => {
    loadOrgs(organization_ids);
  }, [organization_ids]);

  const pretty_name = prettyName(item);

  return (
    <div
      className="base-item-container"
      onClick={() => {
        const parsed = encryptString(id);
        navigate(`/feed/profiles/detail/${parsed}`);
      }}>
      <div className="flex-row justify-between align-start">
        <div className="flex-row  align-center">
          <ProfileImage
            data={profile_image}
            style={{height: '48px', width: '48px'}}
          />
          <div className="padding-left8">
            <div className="flex-row align-center">
              <p className="text-left text-bold">{pretty_name}</p>
              <StatusIcon status={status} />
            </div>
            <p className="text-left text-secondary">{position}</p>
          </div>
        </div>
        {/* <NoteButton item={item} /> */}
      </div>
      <div className="flex-row-wrap">
        {organization_ids.map(id => {
          const {name} = organizations?.[id] ?? {};
          return (
            <p className="text-secondary" key={id}>
              {name}
            </p>
          );
        })}
      </div>
      <SimpleTagDisplay inner_tags={inner_tags} outer_tags={outer_tags} />
    </div>
  );
};

export const ProfileItem = ({item, disabled}) => {
  const navigate = useNavigate();
  const {encryptString} = useCrypto();
  const {
    id,
    email,
    status,
    name,
    first_name,
    last_name,
    position,
    profile_image,
  } = item || {};

  const pretty_name = name || (first_name || '') + ' ' + (last_name || '');

  if (!item) {
    return null;
  }

  return (
    <div
      className="profile-container"
      onClick={() => {
        if (disabled) {
          return;
        }
        const parsed = encryptString(id);
        navigate(`/feed/profiles/detail/${parsed}`);
      }}>
      <div className="flex-column">
        <div className="flex-row align-center">
          <ProfileImage
            data={profile_image}
            style={{height: '50px', width: '50px'}}
          />
          <div>
            <p className="text-left text-bold padding-left8">{pretty_name}</p>
            <p className="text-left padding-left8">{position}</p>
          </div>
        </div>
        <span className="flex-row padding-bottom16">
          {/* <p className="text-secondary">Email: </p> */}
          {/* <a
            href={`mailto:${email}`}
            className="flex margin-h8"
            onClick={() => {
              track('email_clicked', {email, user_id, org_id});
            }}>
            {email}
          </a> */}
        </span>
      </div>
    </div>
  );
};
