// ThreadButtons.js

import React from 'react';
import {GoRss} from 'react-icons/go';
import {
  RxActivityLog,
  RxCheckbox,
  RxClock,
  RxMagnifyingGlass,
} from 'react-icons/rx';
import {VscMention} from 'react-icons/vsc';
import './ThreadButtons.css';

const ThreadButtons = ({
  index,
  setIndex,
  feedCount = 0,
  activityCount = 0,
  tasksCount = 0,
  mentionsCount = 0,
  searchCount = 0,
  draftsCount = 0,
}) => {
  const renderButton = (icon, title, indexName, count) => (
    <button
      className={`inset-button ${index === indexName ? 'active' : ''}`}
      onClick={() => setIndex(indexName)}
      aria-label={title}>
      <div className="button-content">
        {icon}
        {count > 0 && <span className="notification-count">{count}</span>}
        <span className="button-title">{title}</span>
      </div>
    </button>
  );

  return (
    <div className="thread-buttons-container">
      <div className="inset-button-group" data-active={index}>
        <div className="button-row">
          {renderButton(<GoRss />, 'Discover', 'feed', feedCount)}
          {renderButton(
            <RxActivityLog />,
            'Activity',
            'activity',
            activityCount,
          )}
          {renderButton(<RxCheckbox />, 'Tasks', 'tasks', tasksCount)}
          {renderButton(<VscMention />, 'Mentions', 'mentions', mentionsCount)}
        </div>
        <div className="button-row">
          {renderButton(<RxMagnifyingGlass />, 'Find', 'search', searchCount)}
          {renderButton(<RxClock />, 'Scheduled', 'drafts', draftsCount)}
        </div>
        {index !== 2 && <div className="inset-button-slider"></div>}
      </div>
    </div>
  );
};

export default ThreadButtons;
