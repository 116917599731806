import DOMPurify from 'dompurify';
import constants from '../../../components/constants';

const generateThreadInvitation = props => {
  const {thread, profile} = props || {};
  const {title, content, id: thread_id, logo} = thread;
  const sanitizedTitle = DOMPurify.sanitize(title, {ALLOWED_TAGS: []});
  const sanitizedContent = DOMPurify.sanitize(content, {ALLOWED_TAGS: []});

  return `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap" rel="stylesheet">
    <style>
      body { font-family: 'Inter', sans-serif; color: #333; line-height: 1.6; margin: 0; padding: 0; background-color: #f7f7f7; }
      .container { max-width: 600px; margin: 0 auto; padding: 20px; background-color: #ffffff; }
      .logo { text-align: center; margin-bottom: 20px; }
      .content { background-color: #f4f4f4; padding: 24px; border-radius: 8px; }
      .button { display: inline-block; background-color: #4743c5; color: white !important; padding: 12px 24px; text-decoration: none; border-radius: 4px; font-weight: 600; }
      .footer { text-align: center; margin-top: 20px; color: #666; font-size: 14px; }
      .footer a { margin: 0 8px; color: #666; text-decoration: none; }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="logo">
        <img src="${
          constants.publicmind_logo
        }" alt="PublicMind Logo" height="48" width="48" />
        <h2 style="font-weight: 600; margin-top: 12px;">PUBLICMIND</h2>
      </div>
      <div class="content">
        <h3 style="text-align: center; margin-bottom: 24px;">Added to New Thread ${
          profile ? 'by ' + profile : ''
        }</h3>
        <p>Hello,</p>
        <p>You have been added to the following thread:</p>
        <h4 style="margin: 12px 0;">${logo || ''} ${sanitizedTitle}</h4>
        <p>${sanitizedContent}</p>
        <div style="text-align: center; margin: 24px 0;">
          <a href="${
            constants.root_url
          }/threads/${thread_id}" class="button">View Thread</a>
        </div>
      </div>
      <div class="footer">
        <p>Sent by ${profile}</p>
        <div>
          <a href=${constants.copyright_policy}">Copyright Policy</a>
          <a href="${constants.privacy_policy}">Privacy Policy</a>
          <a href="${constants.terms_of_service}">Terms of Service</a>
        </div>
      </div>
    </div>
  </body>
  </html>
  `;
};

export default generateThreadInvitation;
