import React from 'react';

export const Checkbox = props => {
  const {label, children, ...rest} = props;
  const {checked, onChange} = props;

  return (
    <div>
      <div className="checkbox-container">
        <input type="checkbox" {...rest} />
        {children}
        {label && (
          <label
            onClick={() => {
              if (onChange) {
                onChange({target: {checked: !checked}});
              }
            }}>
            {label}
          </label>
        )}
      </div>
    </div>
  );
};

export const FormikCheckbox = props => {
  const {formik, name, label, children} = props;

  return (
    <div>
      <div className="checkbox-container">
        <input type="checkbox" {...formik.getFieldProps(name)} />
        {children}
        {label && <p>{label}</p>}
      </div>
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};
