import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import '../../App.css';
import {track} from '../../api/analytics';
import {LinkButton} from '../../components/buttons/buttons';
import {
  PublicMindLogoDarkNoText,
  PublicMindLogoLightNoText,
} from '../../components/images/logos';
import {FormikTextfield} from '../../components/inputs/textfields';
import {useAuth} from '../../hooks/use-auth';
import {useMounted} from '../../hooks/use-mounted';

const Signin = ({}) => {
  const navigate = useNavigate();
  const isMounted = useMounted();
  const {
    state: {auth},
    signin,
    signOut,
  } = useAuth();

  const [darkMode, setTheme] = useState(false);

  useEffect(() => {
    // Get the data-theme attribute from the documentElement
    const currentTheme = document.documentElement.getAttribute('data-theme');
    setTheme(currentTheme === 'dark' ? true : false);
  }, []);

  const mobile = isMobile || isTablet;

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup.string().max(255).required('Password is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {email, password} = values;

        await signOut();

        const response = await signin(email, password);
        const {success, error, isSignedIn, nextStep, profile} = response;
        if (success && isMounted()) {
          if (isSignedIn) {
            navigate('/');
          } else {
            // EMAIL CONFIRMED
            const {signInStep} = nextStep;
            if (signInStep && signInStep === 'CONFIRM_SIGN_UP') {
              navigate('/confirm', {state: {email, password}});
              return;
            }
            navigate('/signin');
          }
          track('sign_in', {email});
        }
        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        if (isMounted()) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  return (
    <div className="grid-container">
      <div
        className={`${
          mobile ? 'grid-12' : 'grid-7'
        } flex-column align-center justify-center`}>
        {!darkMode && <PublicMindLogoLightNoText />}
        {darkMode && <PublicMindLogoDarkNoText />}
        <h3
          style={{
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 300,
            fontSize: mobile ? 14 : 28,
            letterSpacing: 0.1,
            paddingTop: 16,
            paddingBottom: 16,
          }}>
          PUBLICMIND
        </h3>
        <p
          className="text-secondary padding-bottom16"
          style={{
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 300,
            fontSize: mobile ? 12 : 20,
            letterSpacing: 0.1,
          }}>
          A secure, private network
        </p>
        <p
          className="text-secondary padding-bottom16"
          style={{
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 300,
            fontSize: mobile ? 12 : 20,
            letterSpacing: 0.1,
          }}>
          Discover and host government projects
        </p>
        <p
          className="text-secondary padding-bottom16"
          style={{
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 300,
            fontSize: mobile ? 12 : 20,
            letterSpacing: 0.1,
          }}>
          Find a current member willing to vouch for you to join
        </p>
      </div>
      <div className={mobile ? 'grid-12' : 'grid-4'}>
        <div className="card pretty-form-group">
          <FormikTextfield
            formik={formik}
            header="Email"
            placeholder="xyz@zyx.com"
            type="email"
            name="email"
          />
          <FormikTextfield
            formik={formik}
            header="Password"
            placeholder=""
            name="password"
            type="password"
          />
          {formik.errors.submit && (
            <p className="error">{formik.errors.submit}</p>
          )}
          <button
            type="submit"
            className="button-container"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}>
            LOG IN
          </button>
          <LinkButton
            onClick={() => {
              navigate('/forgot-password');
            }}>
            Forgot Password?
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

export default Signin;
