import {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {track} from '../../api/analytics';
import {useAuth} from '../../hooks/use-auth';
import useCrypto from '../../hooks/use-crypto';
import useLoader from '../../hooks/use-loader';
import useSearchParameters from '../../hooks/use-search-parameters';
import useStringFormatter from '../../hooks/use-string-formatter';
import useUtilities from '../../hooks/use-utilities';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import {LinkButton} from '../buttons/buttons';
import constants from '../constants';
import {SpinningIndicator} from '../loading/loading-indicator';
import {NudgeInvitation} from '../modal/schedule-modal';
import './referralStyles.css';

export const ReferralBox = ({inProfile = false}) => {
  const {copyString} = useUtilities();
  const {createReferralLink} = useSearchParameters();
  const {
    state: {id, current_organization, referrals},
    getReferrals,
  } = useAuth();

  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });
  useEffect(() => {
    const link = createReferralLink();
    setLink(link);
  }, [id, current_organization]);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await getReferrals({
        filter: {referral_user: {eq: id}},
        limit: constants.all_items,
      });
      setLoading(false);
    };
    if (referrals?.length === 0) {
      load();
    }
  }, []);

  const sortedReferrals = useMemo(() => {
    return [...(referrals || [])].sort((a, b) =>
      a.status.localeCompare(b.status),
    );
  }, [referrals]);

  return (
    <div className="referral-box">
      {inProfile && (
        <p
          className="text-secondary bare-border clickable"
          onClick={() => {
            copyString(link);
            setPopup({on: true, message: 'Referral link copied'});

            track('referral_link_copied', {
              link,
              user_id: id,
              org_id: current_organization,
            });
          }}>
          Copy Personal Invite Link
        </p>
      )}
      <div className="flex-row">
        <h4>Invites</h4>
      </div>
      <p className="text-secondary text-left">
        You vouched for ({sortedReferrals?.length ?? 0}) people
      </p>
      <div className="border-bottom margin-v4 margin-v8" />
      {loading && <SpinningIndicator />}
      <div>
        {!!sortedReferrals.length &&
          sortedReferrals.map(referral => {
            return <ReferralItem referral={referral} key={referral.id} />;
          })}
      </div>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </div>
  );
};

const ReferralItem = ({referral}) => {
  const navigate = useNavigate();
  const {loadProfiles} = useLoader();
  const {capitalize} = useStringFormatter();
  const {encryptString} = useCrypto();
  const {id, timestamp, status} = referral;

  const {
    state: {profiles},
  } = useAuth();

  const [active, setActive] = useState(false);

  const profile = profiles?.[id] ?? {email: id};
  const {first_name, last_name} = profile;
  const pretty =
    first_name || last_name ? `${first_name || ''} ${last_name || ''}` : id;

  useEffect(() => {
    loadProfiles([id]);
  }, []);

  return (
    <>
      <div className="flex-row justify-between align-center">
        <div>
          <p
            className={
              status === 'signed_up'
                ? 'text-left text-bold text-14 clickable'
                : 'text-left text-bold text-14'
            }
            key={id}
            onClick={() => {
              if (status === 'signed_up') {
                const parsed = encryptString(id);
                navigate(`/feed/profiles/detail/${parsed}`);
              }
            }}>
            {pretty}
          </p>
          <p className="text-left text-secondary text-12">
            {capitalize(status.replace('_', ' '))}
          </p>
        </div>
        {status !== 'signed_up' ? (
          <LinkButton
            onClick={() => {
              setActive(!active);
            }}>
            Remind
          </LinkButton>
        ) : (
          <span className="fake-button" />
        )}
      </div>

      <NudgeInvitation
        active={active}
        setActive={setActive}
        referral={profile}
      />
    </>
  );
};
