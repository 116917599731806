import {getFileURL} from '../api/s3';
import contactsJSON from '../assets/lists/contacts.json';
import {useAuth} from './use-auth';

/* eslint-disable import/no-anonymous-default-export */
export default () => {
  const {
    state: {id, organizations, profiles},
    getProf,
    getOrg,
  } = useAuth();

  const loadOrgs = async (org_ids, options) => {
    if (!org_ids || !org_ids.length) {
      return [];
    }

    await Promise.all(
      org_ids.map(async id => {
        if (!organizations || organizations[id] === undefined) {
          return getOrg(id, options);
        }
        return null;
      }),
    );
  };

  const loadProfiles = async (profile_ids, options) => {
    if (!profile_ids || !profile_ids.length) {
      return [];
    }

    const profs = await Promise.all(
      profile_ids.map(async id => {
        if (!id) {
          return null;
        }
        if ((id && !profiles) || profiles[id] === undefined) {
          const {success, error, prof} = await getProf(id, options);
          if (success) {
            return prof;
          }
          if (error) {
            return null;
          }
        }
        return null;
      }),
    );

    const mapped = {};
    profs.forEach(prof => {
      if (prof) {
        mapped[prof.id] = prof;
      }
    });

    return mapped;
  };

  const loadWorkspaces = async workspace_ids => {
    if (!workspace_ids || !workspace_ids.length) {
      return;
    }
  };

  const loadContacts = source => {
    try {
      if (!source || !source.length) {
        return [];
      }

      const filtered = contactsJSON.filter(contact => {
        const {college} = contact;
        const included = source.find(src => src.pretty === college);
        return included;
      });
      return filtered;
    } catch (err) {
      return [];
    }
  };

  const loadURLS = async media => {
    try {
      if (!media || !media.length) {
        return [];
      }

      const urls = await Promise.all(
        media.map(async file => {
          const {key} = file;
          const {expiresAt, url} = await getFileURL(key);
          return {...file, url};
        }),
      );
      return urls;
    } catch (err) {
      return [];
    }
  };

  return {
    loadOrgs,
    loadProfiles,
    loadWorkspaces,
    loadContacts,
    loadURLS,
  };
};
