import {dateToTimestamp} from '../api/dates';
import {fetchPageView, setPageView, updateView} from '../api/graphql';
import {useAuth} from './use-auth';

/* eslint-disable import/no-anonymous-default-export */
export default () => {
  const {
    state: {id: source_user},
  } = useAuth();

  const default_analytics = {
    views: 0,
    share_count: 0,
    refer_count: 0,
    opportunity_count: 0,
  };

  const trackPage = async (url, type = 'page_view') => {
    try {
      const data = await fetchPageView(url);
      if (data) {
        const {views, id} = data;
        const view = {
          id,
          views: views + 1,
          last_viewed: dateToTimestamp(),
          type,
        };
        await updateView(view);
        return {...data, ...view};
      } else {
        const view = {
          id: url,
          views: 1,
          last_viewed: dateToTimestamp(),
          type,
        };
        await setPageView(view);
        return {...default_analytics, ...view};
      }
    } catch (err) {
      console.log('err', err);
      return {
        ...default_analytics,
        id: null,
        views: 52,
        last_viewed: dateToTimestamp(),
        type,
      };
    }
  };

  const trackShare = async (post_id, type = 'page_view') => {
    try {
      let url = `/feed/posts/detail/${post_id}`;
      const data = await fetchPageView(url);
      if (data) {
        const {views, share_count = 0, id} = data;
        const view = {
          id,
          views: views + 1,
          share_count: share_count + 1,
          last_viewed: dateToTimestamp(),
          type,
        };
        await updateView(view);
        return {...data, ...view};
      } else {
        const view = {
          id: url,
          views: 1,
          share_count: 1,
          last_viewed: dateToTimestamp(),
          type,
        };
        await setPageView(view);
        return {...default_analytics, ...view};
      }
    } catch (err) {
      console.log('err', err);
      return {
        ...default_analytics,
        id: null,
        views: 52,
        share_count: 1,
        last_viewed: dateToTimestamp(),
        type,
      };
    }
  };

  const trackOpportunity = async (post_id, type = 'page_view') => {
    try {
      let url = `/feed/posts/detail/${post_id}`;

      const data = await fetchPageView(url);
      if (data) {
        const {views, opportunity_count = 0, id} = data;
        const view = {
          id,
          views: views + 1,
          opportunity_count: opportunity_count + 1,
          last_viewed: dateToTimestamp(),
          type,
        };
        await updateView(view);
        return {...data, ...view};
      } else {
        const view = {
          id: url,
          views: 1,
          opportunity_count: 1,
          last_viewed: dateToTimestamp(),
          type,
        };
        await setPageView(view);
        return {...default_analytics, ...view};
      }
    } catch (err) {
      console.log('err', err);
      return {
        ...default_analytics,
        id: null,
        views: 52,
        opportunity_count: 1,
        last_viewed: dateToTimestamp(),
        type,
      };
    }
  };

  const trackRefer = async (post_id, type = 'page_view') => {
    try {
      let url = `/feed/posts/detail/${post_id}`;

      const data = await fetchPageView(url);
      if (data) {
        const {views, refer_count = 0, id} = data;
        const view = {
          id,
          views: views + 1,
          refer_count: refer_count + 1,
          last_viewed: dateToTimestamp(),
          type,
        };
        await updateView(view);
        return {...data, ...view};
      } else {
        const view = {
          id: url,
          views: 1,
          refer_count: 1,
          last_viewed: dateToTimestamp(),
          type,
        };
        await setPageView(view);
        return {...default_analytics, ...view};
      }
    } catch (err) {
      console.log('err', err);
      return {
        ...default_analytics,
        id: null,
        views: 52,
        refer_count: 1,
        last_viewed: dateToTimestamp(),
        type,
      };
    }
  };

  const getPostAnalytics = async post_id => {
    try {
      let url = `/feed/posts/detail/${post_id}`;
      const data = await fetchPageView(url);
      if (data) {
        return data;
      } else {
        return default_analytics;
      }
    } catch (err) {
      console.log('err', err);
      return default_analytics;
    }
  };

  return {
    trackPage,
    trackShare,
    trackOpportunity,
    trackRefer,
    getPostAnalytics,
  };
};
