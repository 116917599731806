import {useFormik} from 'formik';
import React from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {SecondaryButton} from '../../components/buttons/buttons';
import {TagInput} from '../../components/inputs/tag-picker';
import {AuthHeader} from '../../components/layout/layout';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {useAuth} from '../../hooks/use-auth';

const ProfileTagRequests = ({}) => {
  const navigate = useNavigate();

  const mobile = isMobile || isTablet;

  const {
    state: {id, profile},
    updateProfile,
  } = useAuth();

  const formik = useFormik({
    initialValues: {
      opportunity:
        profile?.outer_tags?.filter(tag => tag.includes('opp_')) ?? [],
      collaborator:
        profile?.outer_tags?.filter(tag => tag.includes('collab_')) ?? [],
      skill: profile?.outer_tags?.filter(tag => tag.includes('skill_')) ?? [],
      degree: profile?.outer_tags?.filter(tag => tag.includes('deg_')) ?? [],
      major: profile?.outer_tags?.filter(tag => tag.includes('maj_')) ?? [],
      service: profile?.outer_tags?.filter(tag => tag.includes('serv_')) ?? [],
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values, helpers) => {
      try {
        const {opportunity, collaborator, skill, degree, major, service} =
          values;

        const outer_tags = [
          ...opportunity,
          ...collaborator,
          ...skill,
          ...degree,
          ...major,
          ...service,
        ];

        const status = 'complete';

        const update = {id, status, outer_tags};
        const {success, error} = await updateProfile(update);

        if (success) {
          navigate('/home');
        }

        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <>
      <div className="container">
        <br />
        <AuthHeader
          header="What are you looking to get out of PublicMind?"
          subheader={
            mobile
              ? ''
              : 'Request tags help us understand and prioritize the resources you need. Please add the appropriate tags to your profile here.'
          }
          isMobile={mobile}
        />
        <div className="flex justify-center">
          <div className={mobile ? 'card' : 'card half-width'}>
            <p className="text-secondary">
              Instructions: Choose the categories below and add tags that best
              describe your requests. Specify the skills you need or academic
              majors you are looking for. Select any services or opportunities
              you require as well.
            </p>
            <TagInput formik={formik} />
            <p className="text-secondary">
              You can add or remove tags later in your profile settings.
            </p>
            <div className="flex-row justify-between">
              <SecondaryButton
                type="button"
                onClick={() => {
                  navigate(-1);
                }}>
                BACK
              </SecondaryButton>
              {formik.isSubmitting && <SpinningIndicator />}
              <button
                type="button"
                className="button-container"
                onClick={() => {
                  formik.handleSubmit();
                }}>
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileTagRequests;
