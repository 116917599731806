import {useFormik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import {v4} from 'uuid';
import * as Yup from 'yup';
import {dateToTimestamp} from '../../api/dates';
import {sendPriorityThreadContent} from '../../api/email';
import {useAuth} from '../../hooks/use-auth';
import {useThreads} from '../../hooks/use-threads';
import useUtilities from '../../hooks/use-utilities';
import ChatActionButtons from './chat-action-buttons';
import ChatInput from './chat-input';
import './CreateComment.css';

const CreateComment = ({id}) => {
  const createComment = useRef(null);

  const inputRef = useRef(null);
  const {copyString} = useUtilities();

  const {
    state: {id: user_id, profile},
  } = useAuth();

  const [isExpanded, setIsExpanded] = useState(false);
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [files, setFiles] = useState([]);
  const {
    state: {threads, thread_subs},
    createThreadContent,
    updateThreadSubscription,
    createThreadSubscription,
  } = useThreads();

  const formik = useFormik({
    initialValues: {
      owner_id: user_id,
      type: 'comment',
      status: 'active',
      // task_status: null,
      priority: false,
      content: '',
      media: [],
      mentions: [],
      parent: null,
      scheduled: null,
      reminder: false,
    },

    validationSchema: Yup.object()
      .shape({
        content: Yup.string(),
        media: Yup.array(),
      })
      .test(
        'content-or-media-required',
        'Either content or at least one media item is required',
        function (values) {
          return (
            (values.content && values.content.trim().length > 0) ||
            (values.media && values.media.length > 0)
          );
        },
      ),
    onSubmit: async (values, helpers) => {
      try {
        const {type, scheduled, reminder, mentions} = values;

        const content = {
          id: v4(),
          thread_id: id,
          ...values,
        };

        if (files?.length) {
          content.media = files;
        }

        if (reminder) {
          content.reminder = scheduled - 3600;
          content.created = scheduled;
          content.updated = scheduled;
        } else {
          const now = dateToTimestamp();
          content.reminder = null;
          content.created = now;
          content.updated = now;
        }

        const {success, error} = await createThreadContent(content);

        if (success) {
          // IF SCHEDULED SEND TO SCHEDULER
          // if (status === 'scheduled' && !!scheduled) {
          //   const response = await scheduleContent(content);
          // }
          helpers.resetForm();
          setFiles([]);
          setIsExpanded(false);
          setShowFileUploader(false);
          if (mentions?.length) {
            await sendPriorityThreadContent({
              emails: mentions,
              content,
              profile,
              thread: threads?.[id],
            });
          }
          // UPDATE THE SUB WITH LAST SEEN
          const sub = thread_subs?.[id] ?? null;
          if (sub) {
            const update = {
              id: sub.id,
              thread_id: id,
              last_read: dateToTimestamp(),
            };
            updateThreadSubscription(update);
          } else {
            createThreadSubscription({
              id: v4(),
              user_id,
              thread_id: id,
              status: null,
              last_read: dateToTimestamp(),
            });
          }
        }
        if (error) {
          // Handle error
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        createComment.current &&
        !createComment.current.contains(event.target)
      ) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={createComment} className="thread-reply-container">
      <ChatInput
        thread_id={id}
        formik={formik}
        inputRef={inputRef}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        showFileUploader={showFileUploader}
        files={files}
        setFiles={setFiles}
      />

      <ChatActionButtons
        formik={formik}
        files={files}
        handleSendMessage={formik.handleSubmit}
        inputMessage={formik.values.content}
        setInputMessage={input => {
          formik.setFieldValue('content', input.trim());
        }}
        copyString={copyString}
        setShowFileUploader={setShowFileUploader}
      />
    </div>
  );
};

export default CreateComment;
