import {post} from 'aws-amplify/api';
import generateInvitationNudge from '../assets/html/invitation-nudge';
import generatePlatformInvite from '../assets/html/platform-invite';
import generateSharePriorities from '../assets/html/share-priorities';
import generateAcceptJoinThreadRequest from '../assets/html/threads/accept-join-thread-request';
import threadContentNotification from '../assets/html/threads/content-notification';
import generateJoinThreadRequest from '../assets/html/threads/join-thread-request';
import generateThreadInvitation from '../assets/html/threads/thread-invitation';
import {track} from './analytics';

const apiName = 'externalRestAPI';

const sendEmail = async body => {
  try {
    const {to} = body;

    const valid = isValidEmail(to || '');
    if (!valid) {
      return {success: false, error: 'INVALID EMAIL'};
    }

    const path = '/sendEmail';
    const options = {
      headers: {}, // Optional
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body,
    };
    const postOpp = post({apiName, path, options});
    const {body: data, headers, statusCode} = await postOpp.response;
    const json = await data.json();

    return json;
  } catch (err) {
    track('api_error', {type: 'lambda', function: 'sendEmail', err});
    throw err;
  }
};

const isValidEmail = email => {
  if (!email) {
    return false;
  }
  // Regular expression pattern for validating email addresses
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const sendPrioritiesToEmail = async (member, link, tags, profile, type) => {
  try {
    const {email} = member;
    const {first_name, last_name} = profile || {};
    const pretty_name =
      first_name || last_name ? `${first_name || ''} ${last_name || ''}` : '';

    const htmlContent = generateSharePriorities({profile, tags, link, type});

    const msg = {
      to: email,
      // bcc: constants.contact_email,
      subject: `PublicMind Priorities ${
        pretty_name ? 'from ' + pretty_name : '/ Invite to PublicMind'
      }`,
      text: `PublicMind Priorities ${
        pretty_name ? 'from ' + pretty_name : '/ Invite to PublicMind'
      }`,
      html: htmlContent,
    };

    const response = await sendEmail(msg);
    const {success, error} = response || {};

    return {success, error};
  } catch (err) {
    console.log(err);
  }
};

const sendInvitationNudgeEmail = async ({email, profile, note, link}) => {
  try {
    if (!email) {
      return {success: false, error: true};
    }
    const {first_name, last_name} = profile || {};

    const pretty_name =
      first_name || last_name
        ? `${first_name || ''} ${last_name || ''}`
        : email;
    const htmlContent = generateInvitationNudge({profile, note, link});

    const msg = {
      to: email,
      subject: `Invite Reminder from ${pretty_name}`,
      text: `Invite Reminder from ${pretty_name}`,
      html: htmlContent,
    };
    const response = await sendEmail(msg);
    const {success, error} = response || {};
    return {success, error};
  } catch (err) {
    console.log(err);
    return {success: false, error: err};
  }
};

const sendPlatformInvite = async ({email, profile, link, note}) => {
  try {
    if (!email) {
      return {success: false, error: true};
    }
    const {first_name, last_name} = profile || {};

    const pretty_name =
      first_name || last_name
        ? `${first_name || ''} ${last_name || ''}`
        : email;
    const htmlContent = generatePlatformInvite({profile, link, note});

    const msg = {
      to: email,
      subject: `${pretty_name} invited you to PublicMind 👋`,
      text: `${pretty_name} invited you to PublicMind 👋`,
      html: htmlContent,
    };
    const response = await sendEmail(msg);

    const {success, error} = response || {};
    return {success, error};
  } catch (err) {
    console.log(err);
    return {success: false, error: err};
  }
};

const sendThreadInvitations = async ({emails, thread, profile}) => {
  try {
    await Promise.all(
      emails.map(async email => {
        const recipient_html = generateThreadInvitation({
          thread,
          profile,
        });
        const recipient_body = {
          to: email,
          subject: `${profile} added you to ${thread.logo + ' ' || ''}${
            thread.title
          }`,
          text: `${profile} added you to ${thread.logo + ' ' || ''}${
            thread.title
          }`,
          html: recipient_html,
        };
        await sendEmail(recipient_body);
      }),
    );

    return {success: true, error: null};
  } catch (err) {
    return {success: false, error: err};
  }
};

const sendPriorityThreadContent = async ({
  thread,
  emails,
  content,
  profile,
}) => {
  try {
    await Promise.all(
      emails.map(async email => {
        const recipient_html = threadContentNotification({
          content,
          profile,
          thread,
        });

        const {first_name, last_name} = profile || {};
        const pretty = `${first_name || ''} ${last_name || ''}`;
        const recipient_body = {
          to: email,
          subject: `${pretty} tagged you in ${thread?.title}`,
          text: `${pretty} tagged you in ${thread?.title}`,
          html: recipient_html,
        };
        await sendEmail(recipient_body);
      }),
    );
    return {success: true, error: null};
  } catch (err) {
    return {success: false, error: err};
  }
};

const sendJoinThreadRequestEmail = async ({thread, profile}) => {
  try {
    const {permissions} = thread || {};
    const admins = permissions.filter(mem => {
      if (mem.role === 'owner' || mem.role === 'admin') {
        return true;
      }
      return false;
    });

    await Promise.all(
      admins.map(async user => {
        const {user_id} = user;
        const recipient_html = generateJoinThreadRequest({
          thread,
          profile,
        });

        const recipient_body = {
          to: user_id,
          subject: `Request to Join ${thread.title}`,
          text: `Request to Join ${thread.title}`,
          html: recipient_html,
        };

        await sendEmail(recipient_body);
      }),
    );
    return {success: true, error: null};
  } catch (err) {
    return {success: false, error: err};
  }
};

const sendAcceptJoinThread = async ({thread, profile, request}) => {
  try {
    const {requester_id} = request;

    const recipient_html = generateAcceptJoinThreadRequest({
      thread,
      profile,
    });

    const recipient_body = {
      to: requester_id,
      subject: `Request to Join ${thread.title}`,
      text: `Request to Join ${thread.title}`,
      html: recipient_html,
    };
    await sendEmail(recipient_body);

    return {success: true, error: null};
  } catch (err) {
    return {success: false, error: err};
  }
};

export {
  sendAcceptJoinThread,
  sendEmail,
  sendInvitationNudgeEmail,
  sendJoinThreadRequestEmail,
  sendPlatformInvite,
  sendPrioritiesToEmail,
  sendPriorityThreadContent,
  sendThreadInvitations,
};
