import React from 'react';
import './styles.css';

export const BadgeDot = ({active, style}) => {
  if (!active) {
    return null;
  }
  return <div className="badge-dot" style={style} />;
};

export const BadgeDotRelative = ({active, style}) => {
  if (!active) {
    return null;
  }
  return <div className="badge-dot-relative" style={style} />;
};

export const Badge = ({children, count, style}) => {
  return (
    <div className="badge-wrapper">
      {children}
      {!!count && (
        <span className="badge" style={style}>
          {count}
        </span>
      )}
    </div>
  );
};
