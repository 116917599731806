import React, {useEffect, useRef, useState} from 'react';
import {Mention, MentionsInput} from 'react-mentions';
import constants from '../../components/constants';
import {MultipleFileUploaderSimple} from '../../components/inputs/uploader';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import './ChatInput.css';

const ChatInput = React.memo(
  ({
    formik,
    thread_id,
    inputRef,
    isExpanded,
    setIsExpanded,
    showFileUploader,
    files,
    setFiles,
    isReply,
  }) => {
    const {prettyName} = useStringFormatter();
    const {
      state: {threads},
    } = useThreads();
    const {
      state: {profiles},
    } = useAuth();
    const {members} = threads?.[thread_id] ?? {};
    const containerRef = useRef(null);

    const handleKeyDown = e => {
      if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault(); // Prevent default to avoid newline insertion
        formik.handleSubmit(); // Submit the form
      }
    };

    const handleMention = (id, display) => {
      const {mentions} = formik.values;

      if (!mentions) {
        return;
      }
      if (!mentions?.includes(id)) {
        formik.setFieldValue('mentions', [...mentions, id]);
      }
    };

    const handleDragOver = e => {
      e.preventDefault();
      e.stopPropagation();
      containerRef.current.classList.add('dragover');
    };

    const handleDragLeave = e => {
      e.preventDefault();
      e.stopPropagation();
      containerRef.current.classList.remove('dragover');
    };

    const handleDrop = e => {
      e.preventDefault();
      e.stopPropagation();
      containerRef.current.classList.remove('dragover');

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        setFiles(prevFiles => [...prevFiles, ...e.dataTransfer.files]);
      }
    };

    useEffect(() => {
      setIsExpanded(false);
    }, [setIsExpanded, thread_id]);

    return (
      <div
        ref={containerRef}
        className="reply-popup-container"
        onClick={() => setIsExpanded(true)}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}>
        <MentionsInput
          inputRef={inputRef}
          value={formik.values.content}
          onChange={(event, newValue, newPlainTextValue, mentions) => {
            formik.setFieldValue('content', newValue);
          }}
          onKeyDown={handleKeyDown}
          placeholder={
            isExpanded ? 'Type your reply...' : 'Click to add a comment...'
          }
          rows={isExpanded ? 5 : 1}
          style={{height: isExpanded ? '256px' : '28px'}}
          className="mentions-input">
          <Mention
            trigger="@"
            data={members?.map(email => {
              const profile = profiles?.[email];
              return {id: email, display: prettyName(profile)};
            })}
            appendSpaceOnAdd={true}
            renderSuggestion={(
              suggestion,
              search,
              highlightedDisplay,
              index,
              focused,
            ) => (
              <div
                className={`mentions__suggestions__item ${
                  focused ? 'mentions__suggestions__item--focused' : ''
                }`}>
                <span className="mentions__suggestions__item__display">
                  {suggestion?.display}
                </span>
                <span className="mentions__suggestions__item__id">
                  {'Member'}
                </span>
              </div>
            )}
            className="mentions-input__suggestions2"
            onAdd={handleMention}
            markup="@[__display__](/feed/profiles/detail/__id__)"
            displayTransform={(id, display) => `@${display}`}
          />
        </MentionsInput>

        <MultipleFileUploaderSimple
          showFileUploader={showFileUploader}
          files={files}
          setFiles={setFiles}
          types={[
            ...constants.image_mime_types,
            ...constants.video_mime_types,
            ...constants.document_mime_types,
            ...constants.compressed_mime_types,
            ...constants.audio_mime_types,
          ]}
          limit={constants.file_20mb}
        />
      </div>
    );
  },
);

export default ChatInput;

export const ChatMentionsInput = React.memo(({formik, thread_id}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {threads},
  } = useThreads();
  const {
    state: {profiles},
  } = useAuth();
  const {members} = threads?.[thread_id] ?? {};
  const containerRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [value, setValue] = useState('');

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  const handleMention = (id, display) => {
    const {mentions} = formik.values;

    if (!mentions) {
      return;
    }
    if (!mentions?.includes(id)) {
      formik.setFieldValue('mentions', [...mentions, id]);
    }
  };

  return (
    <div
      ref={containerRef}
      className="reply-popup-container"
      onClick={() => setIsExpanded(true)}>
      <MentionsInput
        value={value}
        onChange={(event, newValue, newPlainTextValue, mentions) => {
          // formik.setFieldValue('content', newValue);
          setValue(newValue);
        }}
        onKeyDown={handleKeyDown}
        placeholder={'Assign to...'}
        rows={isExpanded ? 2 : 1}
        style={{height: isExpanded ? '128px' : '28px'}}
        className="mentions-input">
        <Mention
          trigger="@"
          data={members?.map(email => {
            const profile = profiles?.[email];
            return {id: email, display: prettyName(profile)};
          })}
          appendSpaceOnAdd={true}
          renderSuggestion={(
            suggestion,
            search,
            highlightedDisplay,
            index,
            focused,
          ) => (
            <div
              className={`mentions__suggestions__item ${
                focused ? 'mentions__suggestions__item--focused' : ''
              }`}>
              <span className="mentions__suggestions__item__display">
                {suggestion?.display}
              </span>
              <span className="mentions__suggestions__item__id">
                {suggestion?.id?.split('@')?.[0]}
              </span>
            </div>
          )}
          onAdd={handleMention}
          markup="@[__display__](/feed/profiles/detail/__id__)"
          displayTransform={(id, display) => `@${display}`}
        />
      </MentionsInput>
    </div>
  );
});
