import {useFormik} from 'formik';
import React, {useState} from 'react';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import {sendEmail, sendInvitationNudgeEmail} from '../../api/email';
import subscriptionConfirmationTemplate from '../../assets/html/subscriptionConfirmation';
import {useAuth} from '../../hooks/use-auth';
import useSearchParameters from '../../hooks/use-search-parameters';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import {ErrorButton} from '../buttons/buttons';
import EmailPreviewModal from '../email/email-preview';
import {PublicMindLogoLightNoText} from '../images/logos';
import {FormikTextfield, MultilineTextfield} from '../inputs/textfields';
import {Modal} from './modal';

export const SubscribeModal = ({profile, active, setActive}) => {
  const {
    state: {id},
  } = useAuth();

  const {first_name} = profile || {};
  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });

  const formik = useFormik({
    initialValues: {
      email: id || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Email is required.'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {email} = values;
        const lowered = email.toLowerCase();

        track('user_subscription', {
          subscriber: lowered,
          target: profile.email,
          source: 'profile',
        });

        try {
          const sender_html = subscriptionConfirmationTemplate(profile);
          const sender_body = {
            to: email,
            subject: 'Subscription Confirmation',
            text: 'Subscription Confirmation',
            html: sender_html,
          };
          await sendEmail(sender_body);
        } catch (err) {
          console.log(err);
        }

        setPopup({on: true, message: 'Subscription confirmed!'});
        setActive(false);
        helpers.resetForm();
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  if (!profile) {
    return null;
  }

  return (
    <>
      <Modal active={active} setActive={setActive} disabled_dismiss={true}>
        <div className="padding-32">
          <h4>Subscribe to {first_name || 'them'}?</h4>
          <p className="text-left text-secondary">
            You will receive updates on their activity in your email.
          </p>
          <br />
          {!id && (
            <FormikTextfield
              formik={formik}
              header="Add your email"
              name="email"
            />
          )}
          {formik.errors.submit && (
            <p className="error">{formik.errors.submit}</p>
          )}
          <div className="flex-row justify-center">
            <ErrorButton
              onClick={() => {
                setActive(false);
              }}>
              Cancel
            </ErrorButton>
            <button
              className="button-container"
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}>
              Subscribe
            </button>
          </div>
        </div>
      </Modal>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

export const NudgeInvitation = ({active, setActive, referral}) => {
  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });

  return (
    <>
      {active ? (
        <NudgeInvitationContent
          active={active}
          setActive={setActive}
          referral={referral}
          setPopup={setPopup}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const NudgeInvitationContent = ({active, setActive, referral, setPopup}) => {
  const {createReferralLink} = useSearchParameters();
  const {
    state: {id, profile},
  } = useAuth();

  const {first_name, last_name, email} = referral || {};

  const pretty =
    first_name || last_name ? `${first_name || ''} ${last_name || ''}` : email;

  const [note, setNote] = useState('');

  const link = createReferralLink(email);

  return (
    <Modal active={active} setActive={setActive}>
      <div className="flex-column align-center justify-center padding-bottom8">
        <PublicMindLogoLightNoText />
        <h2 className="padding-top8">Send a Reminder Email</h2>
      </div>
      <p className="text-center text-secondary">
        Add a personal note to {pretty}
      </p>
      <span className="pretty-form-group">
        <MultilineTextfield
          value={note}
          type="text"
          onChange={e => setNote(e.target.value)}
          header="Email Note"
        />
      </span>
      <div className="flex justify-center">
        <button
          className="basic-button"
          disabled={!note}
          onClick={async () => {
            const msg = {email, profile, note, link};
            const {success, error} = await sendInvitationNudgeEmail(msg);
            if (success) {
              setActive(false);
              setNote('');
              setPopup({on: true, message: 'Reminder sent!'});
            }

            if (error) {
              setPopup({on: true, message: 'Something went wrong'});
            }
          }}>
          Send
        </button>
      </div>
      <EmailPreviewModal
        emailType={'followUpNudge'}
        emailProps={{email, profile, note, link}}
      />
    </Modal>
  );
};
