import React, {useEffect, useMemo, useState} from 'react';
import {RxChevronDown, RxChevronUp, RxDotFilled} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../hooks/use-auth';
import {useThreads} from '../../hooks/use-threads';
import './ThreadStyles.css';
const ThreadItem = ({item, onClick, isActive}) => {
  const {id, title, content, updated, last_sender, logo} = item;

  const {
    state: {id: user_id},
  } = useAuth();
  const {
    state: {thread_subs},
  } = useThreads();

  const sub = thread_subs?.[id] ?? null;

  const new_content =
    user_id !== last_sender && (!sub?.last_read || sub?.last_read < updated);

  return (
    <div
      className={`thread-item ${isActive ? 'active' : ''} justify-between`}
      onClick={onClick}>
      <span className={new_content ? 'text-bold' : ''}>
        <p className="thread-item__title">
          {logo}{' '}
          {!new_content && title?.length > 32
            ? title?.slice(0, 32) + '...'
            : new_content && title?.length > 20
            ? title.slice(0, 20) + '...'
            : title}
        </p>
      </span>
      {new_content ? <RxDotFilled color="red" /> : null}
    </div>
  );
};

const ThreadList = ({current, setCurrent, setIndex}) => {
  const navigate = useNavigate();

  const {
    state: {id: userId},
  } = useAuth();
  const {
    state: {threads, thread_ids, thread_subs},
  } = useThreads();

  const [collapsedSections, setCollapsedSections] = useState({
    pinned: false,
    active: false,
    owned: true,
    admin: true,
    collaborator: true,
    subscribed: true,
    direct_messages: true,
  });

  const toggleSection = section => {
    setCollapsedSections(prev => ({...prev, [section]: !prev[section]}));
  };

  const oneWeekAgo = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date.getTime();
  }, []);

  const groupedThreads = useMemo(() => {
    const groups = {
      pinned: [],
      active: [],
      owned: [],
      admin: [],
      collaborator: [],
      subscribed: [],
      direct_messages: [],
    };

    thread_ids.forEach(threadId => {
      const thread = threads[threadId];
      const sub = thread_subs?.[threadId] ?? null;

      // Check if thread is pinned
      if (sub?.priority) {
        groups.pinned.push(threadId);
        return; // Skip further categorization for pinned threads
      }

      // Check if thread is active
      const isUnread =
        userId !== thread.last_sender &&
        (!sub?.last_read || sub?.last_read < thread.updated);
      const isRecentlyActive = thread.updated > oneWeekAgo;
      if (isUnread || isRecentlyActive) {
        groups.active.push(threadId);
        return;
      }

      // Categorize thread based on user's role
      if (thread.type === 'direct') {
        groups.direct_messages.push(threadId);
      } else {
        const userRole = thread.permissions.find(
          p => p.user_id === userId,
        )?.role;
        switch (userRole) {
          case 'owner':
            groups.owned.push(threadId);
            break;
          case 'admin':
            groups.admin.push(threadId);
            break;
          case 'collaborator':
            groups.collaborator.push(threadId);
            break;
          case 'subscriber':
          default:
            groups.subscribed.push(threadId);
            break;
        }
      }
    });

    // Sort threads within each group
    Object.keys(groups).forEach(key => {
      groups[key].sort((a, b) => threads[b].updated - threads[a].updated);
    });

    return groups;
  }, [thread_ids, threads, thread_subs, userId, oneWeekAgo]);

  useEffect(() => {
    if (current) {
      for (const [section, threadIds] of Object.entries(groupedThreads)) {
        if (threadIds.includes(current)) {
          setCollapsedSections(prev => ({...prev, [section]: false}));
          break;
        }
      }
    }
  }, [current, groupedThreads]);

  const renderSection = (title, threadIds, sectionKey) => {
    if (threadIds.length === 0) return null;

    return (
      <div className="thread-group">
        <h5
          className="thread-group__title clickable"
          onClick={() => toggleSection(sectionKey)}>
          {title}{' '}
          {collapsedSections[sectionKey] ? <RxChevronDown /> : <RxChevronUp />}
        </h5>
        {!collapsedSections[sectionKey] && (
          <div className="thread-items">
            {threadIds.map(threadId => (
              <ThreadItem
                key={threadId}
                item={threads[threadId]}
                isActive={current === threadId}
                onClick={() => {
                  setIndex(2);
                  setCurrent(threadId);
                  navigate(`/threads/${threadId}`);
                }}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="thread-items">
      {renderSection('Pinned Threads', groupedThreads.pinned, 'pinned')}
      {renderSection('Unread Threads', groupedThreads.active, 'active')}
      {renderSection('Your Threads', groupedThreads.owned, 'owned')}
      {renderSection('Administrator', groupedThreads.admin, 'admin')}
      {renderSection(
        'Collaborator',
        groupedThreads.collaborator,
        'collaborator',
      )}
      {renderSection('Subscriber', groupedThreads.subscribed, 'subscribed')}
      {renderSection(
        'Direct Threads',
        groupedThreads.direct_messages,
        'direct_messages',
      )}
    </div>
  );
};

export default ThreadList;
