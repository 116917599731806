import EmojiPicker from 'emoji-picker-react';
import React, {useEffect, useRef} from 'react';
import {MdOutlineAddReaction} from 'react-icons/md';

export const EmojiInput = ({emoji, setEmoji, setShowPicker, showPicker}) => {
  const pickerRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        showPicker &&
        pickerRef.current &&
        !pickerRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPicker, setShowPicker]);

  const handleEmojiClick = emojiObject => {
    if (setEmoji) {
      setEmoji(emojiObject.emoji);
    }
    setShowPicker(false);
  };

  return (
    <div style={{position: 'relative'}}>
      <button
        ref={buttonRef}
        className="action-button"
        onClick={() => setShowPicker(!showPicker)}>
        {emoji ? <p>{emoji}</p> : <MdOutlineAddReaction />}
      </button>
      {showPicker && (
        <div
          ref={pickerRef}
          style={{
            position: 'absolute',
            top: 'calc(100% + 10px)',
            right: '-10px',
            zIndex: 10,
          }}>
          <EmojiPicker
            onEmojiClick={handleEmojiClick}
            searchDisabled={false}
            theme="auto"
            reactionsDefaultOpen={true}
            skinTonesDisabled={true}
          />
        </div>
      )}
    </div>
  );
};
