import React, {useEffect, useState} from 'react';
import {track} from '../../api/analytics';
import tags from '../../assets/lists/tags.json';
import {useAuth} from '../../hooks/use-auth';
import constants from '../constants';
import {ArraySearchableDropDown} from '../inputs/drop-down';
import {Textfield} from '../inputs/textfields';
import './styles.css';

export const ProfileFilter = ({setLoading, setShow}) => {
  const {
    state: {profile, profile_filter},
    searchProfiles,
  } = useAuth();

  const [editFilter, setFilter] = useState(profile_filter);
  const {properties} = profile;

  const search = async (filter, options) => {
    setLoading(true);
    const {success, error, data} = await searchProfiles(
      {...filter, properties},
      options,
    );
    setLoading(false);
    track('search', {
      source: 'profile',
      filter: {...filter, properties},
      options,
    });
    return {success, error, data};
  };

  useEffect(() => {
    if (profile_filter) {
      setFilter(profile_filter);
    }
  }, [profile_filter]);

  return (
    <div className="filter-container pretty-form-group">
      <h4>Search Criteria</h4>
      <Textfield
        value={editFilter.email}
        type="text"
        onChange={e => setFilter({...editFilter, email: e.target.value})}
        header="Email"
      />
      <Textfield
        value={editFilter.first_name}
        type="text"
        onChange={e => setFilter({...editFilter, first_name: e.target.value})}
        header="First Name"
      />
      <Textfield
        value={editFilter.last_name}
        type="text"
        onChange={e => setFilter({...editFilter, last_name: e.target.value})}
        header="Last Name"
      />
      {/* <SearchableDropDown
        header="Profile Status"
        setValue={val => {
          setFilter({...editFilter, status: val});
        }}
        onClear={() => {
          setFilter({...editFilter, status: ''});
        }}
        items={[
          {key: 'Active', value: 'complete'},
          {key: 'In Progress', value: 'in_progress'},
          {key: 'Inactive', value: 'unactivated'},
        ]}
      /> */}
      <ArraySearchableDropDown
        header="Find Resources"
        setValues={val => {
          setFilter({...editFilter, inner_tags: val});
        }}
        values={editFilter.inner_tags}
        items={tags}
        mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
      />
      <ArraySearchableDropDown
        header="Find Requests"
        setValues={val => {
          setFilter({...editFilter, outer_tags: val});
        }}
        values={editFilter.outer_tags}
        items={tags}
        mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
      />

      <div className="flex-row justify-center padding-top8">
        <button
          type="submit"
          className="basic-button"
          onClick={async () => {
            setLoading(true);
            setShow(false);
            const options = {limit: constants.org_search_limit};
            await search(editFilter, options);
            setLoading(false);
          }}>
          Search
        </button>
      </div>
    </div>
  );
};
