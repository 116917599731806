import {default as React, useEffect, useRef, useState} from 'react';
import {RiCloseFill} from 'react-icons/ri';
import {RxDragHandleDots2, RxOpenInNewWindow} from 'react-icons/rx';
import generateFollowUpNudge from '../../assets/html/invitation-nudge';
import generatePlatformInvite from '../../assets/html/platform-invite';
import generateSharePriorities from '../../assets/html/share-priorities';
import useStringFormatter from '../../hooks/use-string-formatter';

const EmailPreviewModal = ({emailType, emailProps, previewNote}) => {
  const {prettyTag} = useStringFormatter();

  const [isHovering, setIsHovering] = useState(false);
  const [previewPosition, setPreviewPosition] = useState({top: 0, left: 0});
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({x: 0, y: 0});
  const containerRef = useRef(null);
  const previewRef = useRef(null);

  if (previewNote) {
    emailProps.preview = true;
  }
  const getEmailContent = () => {
    switch (emailType) {
      case 'sendInvite':
        return generatePlatformInvite(emailProps);
      case 'sharePost':
        let {post} = emailProps;
        post.inner_tags = post.inner_tags?.map(x => {
          return prettyTag(x);
        });
        post.outer_tags = post.outer_tags?.map(x => {
          return prettyTag(x);
        });
        break;
      case 'sharePriorities':
        return generateSharePriorities(emailProps);
      case 'followUpNudge':
        return generateFollowUpNudge(emailProps);
      default:
        return '<p>Invalid email type</p>';
    }
  };

  const closePreview = () => {
    setIsHovering(false);
  };

  const emailContent = getEmailContent();

  useEffect(() => {
    if (isHovering) {
      setPreviewPosition({top: 8, left: 8});
    }
  }, [isHovering]);

  const handleMouseDown = e => {
    if (previewRef.current && previewRef.current.contains(e.target)) {
      setIsDragging(true);
      const rect = previewRef.current.getBoundingClientRect();
      setDragOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    }
  };

  const handleMouseMove = e => {
    if (isDragging) {
      const newLeft = e.clientX - dragOffset.x;
      const newTop = e.clientY - dragOffset.y;
      setPreviewPosition({top: newTop, left: newLeft});
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  return (
    <>
      <p
        ref={containerRef}
        onClick={() => setIsHovering(true)}
        className="flex text-secondary text-12 align-center justify-center clickable">
        {'Email Preview '}
        <RxOpenInNewWindow />
      </p>

      {isHovering ? (
        <div
          ref={previewRef}
          style={{
            position: 'fixed',
            top: `${previewPosition.top}px`,
            left: `${previewPosition.left}px`,
            width: '500px',
            height: '600px',
            maxHeight: '90vh',
            maxWidth: '90vw',
            background: 'white',
            border: '1px solid #ccc',
            borderRadius: '12px',
            boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
            zIndex: 1000,
            overflow: 'hidden',
            transition: isDragging ? 'none' : 'all 0.5s ease-in-out',
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
          onMouseDown={handleMouseDown}>
          <div
            style={{
              width: '100%',
              height: '100%',
              overflow: 'auto',
              padding: '4px 16px 0px 16px',
              boxSizing: 'border-box',
            }}>
            <div className="flex justify-between align-center">
              <RxDragHandleDots2 size={'18px'} />
              <p className="text-secondary text-12">Email Preview</p>
              <RiCloseFill
                size={'18px'}
                onClick={closePreview}
                className="clickable"
              />
            </div>
            <iframe
              srcDoc={emailContent}
              style={{
                width: '100%',
                height: 'calc(100% - 30px)',
                border: 'none',
              }}
              title="Email Preview"
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default EmailPreviewModal;
