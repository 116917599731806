import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {useAuth} from '../../hooks/use-auth';

const Root = () => {
  const navigate = useNavigate();
  const {
    state: {processing, auth, profile, organizations},
  } = useAuth();

  const {status} = profile || {};

  useEffect(() => {
    // WAIT UNTIL HAVE DATA
    if (processing) {
      return;
    }

    // IF USER IS LOGGED IN HANDLE ROUTING
    if (auth) {
      if (status === 'confirmed') {
        navigate('/introduce-yourself');
        return;
      }

      navigate('/threads');

      return;
    } else {
      // IF NO USER HAVE THEM SIGN IN
      navigate('/signin');
      return;
    }
  }, [processing, auth, status]);

  return (
    <div className="base-container">
      <SpinningIndicator />
    </div>
  );
};

export default Root;
