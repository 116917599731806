import React, {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';
import constants from '../../components/constants';
import {Textfield} from '../../components/inputs/textfields';
import {JoinOrganizationItem} from '../../components/item-details/organization-item';
import {AuthHeader} from '../../components/layout/layout';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {useAuth} from '../../hooks/use-auth';

const FindOrganization = ({}) => {
  const navigate = useNavigate();
  const {
    state: {org_search, organizations},
    searchOrgs,
  } = useAuth();

  const mobile = isMobile || isTablet;

  const [editFilter, setFilter] = useState({query_name: ''});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const end = page * constants.org_search_limit;

  const search = async (filter, options, paginate) => {
    setLoading(true);
    const {success, error, data} = await searchOrgs(filter, options, paginate);
    setLoading(false);
    return {success, error, data};
  };

  // INITIAL LOAD IN
  useEffect(() => {
    if (!org_search?.length) {
      search(editFilter, {limit: constants.org_search_limit});
    }
  }, []);

  return (
    <>
      <div className="container">
        <AuthHeader
          header="Join Your Organization"
          subheader="Search for your organization (e.g. Clemson University, Ritedose) or create it if you can't find it."
        />
      </div>
      <div className="flex justify-center">
        <div className={mobile ? 'card' : 'card grid-6'}>
          <div className="flex-row justify-center align-center pretty-form-group">
            <Textfield
              value={editFilter.query_name}
              type="text"
              onChange={e =>
                setFilter({...editFilter, query_name: e.target.value})
              }
              placeholder="Search..."
              onEnter={async () => {
                setPage(1);
                const options = {
                  limit: constants.org_search_limit,
                };
                const {success, error, data} = await search(
                  editFilter,
                  options,
                );
              }}
            />
            <span className="padding-top8" />
            <button
              className="basic-button"
              onClick={async () => {
                setPage(1);
                const options = {
                  limit: constants.org_search_limit,
                };
                const {success, error, data} = await search(
                  editFilter,
                  options,
                );
              }}>
              Search
            </button>
            <button
              className="basic-button-secondary"
              onClick={() => {
                navigate('/create-organization');
              }}>
              Create Your Own
            </button>
          </div>
          {loading && <SpinningIndicator />}
          {org_search.length ? (
            org_search.slice(0, end).map(id => {
              return <JoinOrganizationItem key={id} item={organizations[id]} />;
            })
          ) : loading ? null : (
            <p className="text-secondary padding-top16">
              No organizations matched your search.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default FindOrganization;
