import {useNavigate} from 'react-router-dom';
import {track} from '../api/analytics';
import constants from '../components/constants';
import {useAuth} from './use-auth';

/* eslint-disable import/no-anonymous-default-export */
export default () => {
  const navigate = useNavigate();
  const {searchProfiles} = useAuth();

  const easySearch = (type, inner_tags, outer_tags) => {
    const user_options = {limit: constants.org_search_limit};

    switch (type) {
      case 'user':
        const user_filter = {
          email: '',
          first_name: '',
          last_name: '',
          status: '',
          inner_tags,
          outer_tags,
        };

        searchProfiles(user_filter, user_options);
        track('search', {
          source: 'profile',
          filter: user_filter,
          options: user_options,
          quick: true,
        });
        navigate('/feed', {state: {start_index: 1}});
        break;
      case 'organization':
      default:
        return;
    }
  };

  return {easySearch};
};
