import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {track} from '../../api/analytics';
import constants from '../../components/constants';
import {OrganizationFilter} from '../../components/filters/organization-filter';
import {ProfileFilter} from '../../components/filters/profile-filter';
import {ThreadFilter} from '../../components/filters/thread-filter';
import {DetailedOrganizationItem} from '../../components/item-details/organization-item';
import {DetailedProfileItem} from '../../components/item-details/profile-item';
import {ThreadItem} from '../../components/item-details/thread-item';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {Tab} from '../../components/tab/tab';
import {useAuth} from '../../hooks/use-auth';
import {useThreads} from '../../hooks/use-threads';

const Search = ({}) => {
  const {state} = useLocation();

  const {start_index} = state || {};

  const [index, setIndex] = useState(start_index || 0);
  const [loading, setLoading] = useState(false);

  const tabs = [
    {tab: 'All Threads', index: 0},
    {tab: 'People', index: 1},

    // {tab: 'Organizations', index: 3},
  ];

  return (
    <div className="page-container">
      <Tab items={tabs} index={index} setIndex={setIndex} />
      {index === 0 && (
        <ThreadSearch loading={loading} setLoading={setLoading} />
      )}
      {index === 1 && (
        <ProfileSearch loading={loading} setLoading={setLoading} />
      )}
      {/* {index === 3 && (
        <OrganizationSearch loading={loading} setLoading={setLoading} />
      )} */}
    </div>
  );
};

const ProfileSearch = ({loading, setLoading}) => {
  const {
    state: {
      profiles,
      profile_search,
      profile_filter,
      profile_token,
      profiles_loaded,
    },
    searchProfiles,
  } = useAuth();

  const search = async (filter, options) => {
    setLoading(true);

    const filt = {...filter};
    const {success, error, data} = await searchProfiles(filt, options);
    setLoading(false);
    track('database_search', {source: 'profile', filter: filt, options});
    return {success, error, data};
  };

  // INITIAL LOAD IN
  useEffect(() => {
    if (!profiles_loaded) {
      search(profile_filter, {
        limit: constants.org_search_limit,
        nextToken: profile_token,
      });
    }
  }, [profiles_loaded]);

  return (
    <div className="grid-container">
      <div className="grid-8">
        <div className="card-light">
          {!!profile_search.length ? (
            profile_search.map(id => {
              return <DetailedProfileItem key={id} item={profiles[id]} />;
            })
          ) : loading ? null : (
            <p className="text-secondary padding-top16">
              No person matched your search.
            </p>
          )}
          {loading && <SpinningIndicator />}
          {!loading && profile_token && !!profile_search.length ? (
            <div className="flex justify-center">
              <button
                onClick={async () => {
                  const options = {
                    limit: constants.org_search_limit,
                    nextToken: profile_token,
                  };
                  const {success, error, data} = await search(
                    profile_filter,
                    options,
                  );
                }}>
                SEE MORE
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <ProfileFilter setLoading={setLoading} setShow={() => {}} />
    </div>
  );
};

const OrganizationSearch = ({}) => {
  const {
    state: {organizations, org_search, org_filter, orgs_loaded, org_token},
    searchOrgs,
  } = useAuth();

  const [editFilter, setFilter] = useState(org_filter);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const end = page * constants.org_search_limit;

  useEffect(() => {
    setFilter(org_filter);
  }, [org_filter]);

  const search = async (filter, options, paginate) => {
    setLoading(true);
    const {success, error, data} = await searchOrgs(filter, options, paginate);
    setLoading(false);
    track('database_search', {source: 'organization', filter, options});
    return {success, error, data};
  };

  // INITIAL LOAD IN
  useEffect(() => {
    if (!orgs_loaded) {
      search(editFilter, {
        limit: constants.org_search_limit,
        nexToken: org_token,
      });
    }
  }, []);

  return (
    <div className="grid-container">
      <div className="grid-8">
        <div className="card-light">
          {org_search.length ? (
            org_search.slice(0, end).map(id => {
              return (
                <DetailedOrganizationItem key={id} item={organizations[id]} />
              );
            })
          ) : loading ? null : (
            <p className="text-secondary padding-top16">
              No organizations matched your search. Try reducing the number of
              filters if you're using several.
            </p>
          )}
          {loading && <SpinningIndicator />}
          {!loading && org_token && org_search.length && org_search.length ? (
            <div className="flex justify-center">
              <button
                onClick={async () => {
                  setPage(page + 1);
                  const options = {
                    limit: constants.org_search_limit,
                    nextToken: org_token,
                  };
                  const {success, error, data} = await search(
                    org_filter,
                    options,
                    true,
                  );
                }}>
                SEE MORE
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <OrganizationFilter setLoading={setLoading} setShow={() => {}} />
    </div>
  );
};

const ThreadSearch = ({}) => {
  const {
    state: {
      thread_search,
      threads,
      thread_token,
      thread_filter,
      threads_search_loaded,
    },
    searchThreads,
  } = useThreads();

  const [loading, setLoading] = useState(false);

  const search = async (filter, options) => {
    setLoading(true);
    const {success, error, data} = await searchThreads(filter, options);
    setLoading(false);
    track('database_search', {source: 'threads', filter, options});
    return {success, error, data};
  };

  // INITIAL LOAD IN
  useEffect(() => {
    if (!threads_search_loaded) {
      search(thread_filter, {
        limit: constants.org_search_limit,
        nextToken: thread_token,
      });
    }
  }, [threads_search_loaded]);

  return (
    <div className="grid-container">
      <div className="grid-8">
        {thread_search.length ? (
          thread_search.map(id => {
            const item = threads?.[id] ?? {};
            return <ThreadItem item={item} key={id} />;
          })
        ) : loading ? null : (
          <p className="text-secondary padding-top16">
            No threads matched your search. Try reducing the number of filters
            if you're using several.
          </p>
        )}
        {loading && <SpinningIndicator />}
        {!loading &&
        thread_token &&
        thread_search.length &&
        thread_search.length ? (
          <div className="flex justify-center">
            <button
              onClick={async () => {
                const options = {
                  limit: constants.org_search_limit,
                  nextToken: thread_token,
                };
                const {success, error, data} = await search(
                  thread_filter,
                  options,
                );
              }}>
              SEE MORE
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>

      <ThreadFilter setLoading={setLoading} />
    </div>
  );
};

export default Search;
