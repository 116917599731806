import React, {useState} from 'react';
import {MdOutlineAttachment} from 'react-icons/md';
import {RiPushpinFill, RiPushpinLine} from 'react-icons/ri';
import {RxCopy, RxTrash} from 'react-icons/rx';
import {ScheduleContentModal} from '../modal/thread-modals';
import './ChatActionButtons.css';

const ChatActionButtons = React.memo(
  ({
    formik,
    handleSendMessage,
    inputMessage,
    setInputMessage,
    copyString,
    setShowFileUploader,
    isEdit = false,
    files,
  }) => {
    return (
      <div className="flex-row justify-between">
        <CommentButtons
          formik={formik}
          handleSendMessage={handleSendMessage}
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          copyString={copyString}
          setShowFileUploader={setShowFileUploader}
          isEdit={isEdit}
          files={files}
        />
      </div>
    );
  },
);

const CommentButtons = ({
  formik,
  handleSendMessage,
  inputMessage,
  setInputMessage,
  copyString,
  setShowFileUploader,
  isEdit = false,
  files,
}) => {
  const prio = formik?.values?.priority || false;
  const [schedule, setSchedule] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  return (
    <div className="button-group">
      <div className="left-buttons">
        <button
          type="submit"
          onClick={() => {
            setResponseMessage('Commenting...');
            handleSendMessage();
          }}
          disabled={
            (!inputMessage.trim() && files?.length === 0) || formik.isSubmitting
          }
          className={formik.isSubmitting ? 'loading' : ''}
          title={isEdit ? 'Save changes' : 'Post comment'}>
          {formik.isSubmitting ? responseMessage : isEdit ? 'Save' : 'Comment'}
        </button>
      </div>
      {!isEdit && (
        <div className="left-buttons">
          <button
            onClick={() => {
              setSchedule(!schedule);
              setResponseMessage('Scheduling...');
            }}
            disabled={
              (!inputMessage.trim() && files?.length === 0) ||
              formik.isSubmitting
            }
            className={formik.isSubmitting ? 'loading' : ''}
            title="Schedule comment for later">
            {formik.isSubmitting ? responseMessage : 'Schedule'}
          </button>
        </div>
      )}
      <div className="right-buttons">
        <button
          onClick={() => setShowFileUploader(prev => !prev)}
          title="Attach file">
          <MdOutlineAttachment />
        </button>
        <button onClick={() => copyString(inputMessage)} title="Copy text">
          <RxCopy />
        </button>
        <button
          onClick={() => {
            setInputMessage('');
            formik.setFieldValue('media', []);
          }}
          title="Clear input">
          <RxTrash />
        </button>
        <button
          onClick={() => formik.setFieldValue('priority', !prio)}
          title={prio ? 'Unpin comment' : 'Pin comment'}>
          {prio ? <RiPushpinFill /> : <RiPushpinLine />}
        </button>
      </div>
      <ScheduleContentModal
        active={schedule}
        setActive={setSchedule}
        formik={formik}
      />
    </div>
  );
};

export default ChatActionButtons;
