import {useFormik} from 'formik';
import {useState} from 'react';
import * as Yup from 'yup';
import '../../App.css';
import {track} from '../../api/analytics';
import {sendPlatformInvite} from '../../api/email';
import {useAuth} from '../../hooks/use-auth';
import useSearchParameters from '../../hooks/use-search-parameters';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import EmailPreviewModal from '../email/email-preview';
import {PublicMindLogoLightNoText} from '../images/logos';
import {EmailInput} from '../inputs/email-input';
import {MultilineFormikTextfield} from '../inputs/textfields';
import {SpinningIndicator} from '../loading/loading-indicator';
import {Modal} from './modal';

export const InviteModal = ({active, setActive}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <InviteModalContent
          active={active}
          setActive={setActive}
          setPopup={setPopup}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const InviteModalContent = ({active, setActive, setPopup}) => {
  const {createReferralLink} = useSearchParameters();

  const {
    state: {id, current_organization, profile},
    getProf,
    inviteMember,
    updateReferral,
    updateGroup,
  } = useAuth();

  const handleInvite = async ({email, admin, helpers, note}) => {
    try {
      // CHECK IF CURRENT USER
      const {success, error, prof} = await getProf(email);
      const link = createReferralLink(email);

      // IF ACTIVE MEMBER SEND AN INVITE
      if (success) {
        sendPlatformInvite({
          email,
          profile,
          link,
          note,
        });
        setActive(false);
        setPopup({
          on: true,
          message: 'Email invite sent to: ' + email,
        });
        helpers.resetForm();
      } else {
        // IF NEW AND INVITING SEND REFERRAL

        const base_user = {
          id: email,
          email,
          referral_user: id,
        };

        const {success, error} = await inviteMember(
          base_user,
          link,
          profile,
          note,
        );

        // IF SUCCESS
        if (success) {
          // MAKE A REFERRAL
          await updateReferral(
            {
              id: email,
              status: 'invited',
              referral_org: current_organization,
              referral_user: id,
            },
            false,
          );
          setPopup({
            on: true,
            message: 'Email invite sent to: ' + email,
          });
          helpers.resetForm();
          setActive(false);
          track('invitation_sent', {user: base_user});
        }

        if (error) {
          let pretty_error = error;
          setPopup({
            on: true,
            message: 'Error sending invite to: ' + email,
          });
          if (error === 'Item already exists.') {
            pretty_error = 'A user with this email already exists.';
          }

          helpers.setStatus({success: false});
          helpers.setErrors({submit: pretty_error});
          helpers.setSubmitting(false);
          return;
        }
      }
    } catch (err) {
      throw err;
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      admin: false,
      note: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {email, admin, note} = values;

        const trimmed = email?.toLowerCase()?.trim();
        await handleInvite({email: trimmed, admin, note, helpers});
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <div className="invite-modal-header flex-column align-center">
        <PublicMindLogoLightNoText />
        <h2 className="invite-modal-title padding-top8">Send Email Invite</h2>
        <p className="text-secondary">
          Invite a trusted person to the PublicMind platform
        </p>
      </div>

      <div className="grid-container justify-center">
        <div className="grid-7 invite-modal-form-group">
          {/* <FormikTextfield formik={formik} name="email" header="Email" /> */}
          <EmailInput
            formik={formik}
            name="email"
            type="text"
            header="Person's Email"
          />
        </div>
      </div>
      <div className="grid-container justify-center">
        <div className="grid-7 invite-modal-form-group">
          <MultilineFormikTextfield
            formik={formik}
            name="note"
            type="text"
            header="Email Note"
          />
        </div>
      </div>
      {formik.isSubmitting && <SpinningIndicator />}
      {!formik.isSubmitting && (
        <div className="flex justify-center padding-top8">
          <button
            type="submit"
            className="basic-button"
            disabled={formik.isSubmitting || !formik.values.email}
            onClick={formik.handleSubmit}>
            Send
          </button>
        </div>
      )}
      <span className="padding-top8" />
      <EmailPreviewModal
        emailType={'sendInvite'}
        emailProps={{
          link: '',
          profile,
          note: formik?.values?.note ?? '',
        }}
      />
    </Modal>
  );
};
