import chrono from 'chrono-node';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import {sendThreadInvitations} from '../../api/email';
import '../../App.css';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import {TypeToggle, UpdateThread} from '../../pages/threads/create-thread';
import {RenderMemberContent} from '../../pages/threads/ThreadInfoPopup';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import constants from '../constants';
import {FormikCheckbox} from '../inputs/checkbox';
import {DateInput, TimeInput} from '../inputs/date-input';

import {FormikSearchableDropDown} from '../inputs/drop-down';
import {PermissionRoleInput, PermissionsInput} from '../inputs/email-input';
import {EmojiInput} from '../inputs/emoji-input';
import {
  FormikTextfield,
  MultilineFormikTextfield,
  Textfield,
} from '../inputs/textfields';

import {BulkEmailUploader} from '../inputs/email-input';

import {MultipleFileUploader} from '../inputs/uploader';
import {Modal} from './modal';

export const EditThreadModal = ({active, setActive, thread}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <EditThreadContentModal
          active={active}
          setActive={setActive}
          setPopup={setPopup}
          thread={thread}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const EditThreadContentModal = ({active, setActive, setPopup, thread}) => {
  const {updateThread} = useThreads();
  const {
    state: {id: user_id},
  } = useAuth();

  const formik = useFormik({
    initialValues: {
      id: thread.id,
      owner_id: thread.owner_id,
      status: thread.status,
      security_level: thread?.security_level,
      permissions: thread?.permissions,
      members: thread?.members,
      media: thread.media,
      title: thread.title,
      content: thread.content,
      parent: thread.parent,
      expiration: thread.expiration,
      logo: thread.logo,
      tags: thread.tags,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {id} = thread;
        const update = {
          id,
          ...values,
        };

        const {success, error} = await updateThread(update);

        if (success) {
          track('thread_updated', {update, user_id});
          helpers.resetForm();
          setActive(false);
          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <UpdateThread formik={formik} />
    </Modal>
  );
};
export const EditThread = ({active, setActive, setPopup, thread}) => {
  const {updateThread} = useThreads();

  const formik = useFormik({
    initialValues: {
      id: thread.id,
      owner_id: thread.owner_id,
      status: thread.status,
      media: thread.media,
      title: thread.title,
      content: thread.content,
      parent: thread.parent,
      expiration: thread.expiration,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      content: Yup.string().required('Content is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {id} = thread;
        const update = {
          id,
          ...values,
        };

        const {success, error} = await updateThread(update);

        if (success) {
          helpers.resetForm();

          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return <UpdateThread formik={formik} isSmall={true} />;
};

export const EditContentModal = ({active, setActive, content}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <EditContentContent
          active={active}
          setActive={setActive}
          setPopup={setPopup}
          content={content}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const EditContentContent = ({active, setActive, setPopup, content}) => {
  const {updateContent} = useThreads();

  const [files, setFiles] = useState(content.media ?? []);

  const formik = useFormik({
    initialValues: {
      content: content.content ?? '',
      media: content.media ?? [],
    },
    validationSchema: Yup.object({
      content: Yup.string().required('Content is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {id} = content;
        const update = {
          id,
          ...values,
          updated: dateToTimestamp(),
        };

        if (files?.length) {
          update.media = files;
        }

        const {success, error} = await updateContent(update);

        if (success) {
          helpers.resetForm();
          setFiles([]);
          setActive(false);
          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <h4>Update Content</h4>
      <MultilineFormikTextfield
        formik={formik}
        name={'content'}
        header="Content"
      />

      <MultipleFileUploader
        files={files}
        setFiles={setFiles}
        types={[
          ...constants.image_mime_types,
          ...constants.video_mime_types,
          ...constants.document_mime_types,
          ...constants.compressed_mime_types,
          ...constants.audio_mime_types,
        ]}
        limit={constants.file_20mb}
      />

      <div className="flex justify-center">
        <button onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export const EditThreadPermissionsModal = ({active, setActive, thread}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <EditPermissionsContent
          active={active}
          setActive={setActive}
          setPopup={setPopup}
          thread={thread}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const EditPermissionsContent = ({active, setActive, setPopup, thread}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {id: user_uid, profile},
  } = useAuth();
  const {updateThread} = useThreads();

  const [edit, setEdit] = useState(null);

  const {id, permissions} = thread || {};

  const formik = useFormik({
    initialValues: {
      permissions: thread?.permissions ?? [],
    },
    validationSchema: Yup.object({}),

    onSubmit: async (values, helpers) => {
      try {
        const {permissions} = values;
        const {id} = thread;

        const members = permissions.map(item => item.user_id);

        const update = {
          id,
          permissions,
          members,
        };

        const {success, error} = await updateThread(update);

        if (success) {
          const new_members = members?.filter(mem => {
            if (!thread?.members?.includes(mem)) {
              return true;
            }
            return false;
          });
          if (new_members?.length) {
            const {success, error} = await sendThreadInvitations({
              emails: new_members,
              thread,
              profile: prettyName(profile),
            });
          }
          helpers.resetForm();
          setActive(false);
          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <span className="pretty-form-group">
        {edit ? (
          <div>
            <h4>Edit {edit.user_id}</h4>
            <div className="flex-column justify-between align-center">
              <PermissionRoleInput permission={edit} setPermission={setEdit} />
            </div>
            <br />
            <div className="flex-column align-center">
              <div className="flex-row justify-center">
                <button
                  className="basic-button-secondary"
                  onClick={() => {
                    setEdit(null);
                  }}>
                  Cancel
                </button>
                <button
                  className="basic-button"
                  onClick={async () => {
                    const updated_permissions = permissions.map(item => {
                      if (item.user_id === edit.user_id) {
                        return {...item, ...edit, updated: dateToTimestamp()};
                      }
                      return item;
                    });
                    const update = {
                      id,
                      permissions: updated_permissions,
                    };
                    formik.setFieldValue('permissions', updated_permissions);

                    await updateThread(update);
                    setEdit(null);
                  }}>
                  Save
                </button>
              </div>
              <span className="padding-top8" />
              <button
                className="basic-button-secondary-critical"
                onClick={async () => {
                  const {user_id} = edit;
                  const filtered_permissions = permissions?.filter(
                    user => user.user_id !== user_id,
                  );
                  const filtered_members = filtered_permissions.map(
                    item => item.user_id,
                  );
                  const update = {
                    id,
                    permissions: filtered_permissions,
                    members: filtered_members,
                  };
                  await updateThread(update);
                  formik.setFieldValue('permissions', filtered_permissions);
                  track('thread_permissions_edited', {
                    update,
                    user_id: user_uid,
                  });
                  setEdit(null);
                }}>
                Remove User
              </button>
            </div>
          </div>
        ) : (
          <div>
            <h4>Edit Thread Members</h4>
            <BulkEmailUploader formik={formik} />
            <PermissionsInput
              formik={formik}
              name="permissions"
              header="Thread Members"
            />
            <br />
            <p className="text-bold">Thread Members</p>
            <RenderMemberContent
              thread={formik.values}
              button={{
                text: 'Edit',
                onClick: item => {
                  setEdit(item);
                },
              }}
            />
            <br />
            <div className="flex justify-center">
              <button
                className="basic-button"
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}>
                Save
              </button>
            </div>
          </div>
        )}
      </span>
    </Modal>
  );
};

export const ScheduleContentModal = ({active, setActive, formik}) => {
  return (
    <>
      {active ? (
        <ScheduleContentContent
          active={active}
          setActive={setActive}
          formik={formik}
        />
      ) : null}
    </>
  );
};

const ScheduleContentContent = ({active, setActive, formik}) => {
  const [dateString, setDateString] = useState('');
  const [chosenDate, setDate] = useState(null);
  const [inputDate, setInputDate] = useState('');
  const [inputTime, setInputTime] = useState('');

  useEffect(() => {
    const scheduledTime = chrono.parseDate(dateString);
    setDate(scheduledTime);
    setInputDate(scheduledTime?.toISOString()?.split('T')[0]);
    setInputTime(scheduledTime?.toTimeString()?.slice(0, 5));
  }, [dateString]);

  useEffect(() => {
    if (inputDate && inputTime) {
      const newDate = new Date(`${inputDate}T${inputTime}`);
      setDate(newDate);
    }
  }, [inputDate, inputTime]);

  const now = new Date();
  const past = chosenDate && chosenDate < now;
  const formattedDate = chosenDate?.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
  const formattedTime = chosenDate?.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });

  return (
    <Modal active={active} setActive={setActive}>
      <span className="pretty-form-group">
        <h4>Schedule your message</h4>
        <p className="text-secondary">
          Type anything like 'August 5th at 1pm EST' or '2 weeks' or 'today at
          5pm ET'
        </p>
        <br />
        <Textfield
          value={dateString}
          onChange={e => setDateString(e.target.value)}
          placeholder="Tomorrow at..."
        />
        {chosenDate && (
          <div className="grid-container">
            <div className="grid-6">
              <DateInput
                header="Date"
                value={inputDate}
                onChange={e => setInputDate(e.target.value)}
              />
            </div>
            <div className="grid-6">
              <TimeInput
                header="Time"
                value={inputTime}
                onChange={e => setInputTime(e.target.value)}
              />
            </div>
          </div>
        )}
        {past && <p className="error">Choose a time in the future.</p>}
        <br />
        <FormikCheckbox
          label="Remind me before sending"
          formik={formik}
          name="reminder"
        />
        <div className="flex justify-center padding-top8">
          <button
            className="basic-button"
            disabled={!chosenDate || past || formik.isSubmitting}
            onClick={async () => {
              formik.setFieldValue('status', 'scheduled');
              formik.setFieldValue('scheduled', dateToTimestamp(chosenDate));
              await formik.handleSubmit();
              setActive(false);
            }}>
            Schedule{' '}
            {formattedDate || formattedTime ? (
              <strong>
                for {formattedDate} at {formattedTime}
              </strong>
            ) : null}
          </button>
        </div>
      </span>
    </Modal>
  );
};

export const BreakoutThreadModal = ({active, setActive, content}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();
  const {
    state: {id, profiles, profile},
  } = useAuth();
  const {
    state: {threads},
    createThread,
  } = useThreads();

  const {thread_id, content: comment_content, owner_id} = content;
  const thread = threads?.[thread_id] ?? {};
  const {permissions, title, logo, security_level} = thread;
  const [emojiPicker, setEmoji] = useState(false);
  const breakout_type =
    security_level.slice(0, 1).toUpperCase() + security_level.slice(1) ||
    'Open';

  const [toggleType, setToggleType] = useState(breakout_type);

  const now = dateToTimestamp();

  const formik = useFormik({
    initialValues: {
      id: v4(),
      owner_id: id,
      status: 'active',
      type: 'sub-thread',
      security_level: toggleType.toLowerCase(),
      permissions,
      media: [],
      tags: [],
      title: `Break out from ${prettyName(profiles?.[owner_id])}'s comment`,
      content: comment_content,
      logo: '',
      parent: content.id,
      expiration: null,
      ai_assisted: false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {permissions} = values;
        values.security_level = toggleType.toLowerCase();

        const members = permissions.map(permission => permission.user_id);
        const thread = {
          ...values,
          members,
          created: now,
          updated: now,
        };

        const {success, error} = await createThread(thread);
        if (success) {
          track('thread_created', {thread, user_id: id});
          helpers.resetForm();
          navigate(`/threads/${thread?.id}`);
          const other_members = members.filter(mem => mem !== id);
          if (other_members?.length) {
            const {success, error} = await sendThreadInvitations({
              emails: other_members,
              thread,
              profile: prettyName(profile),
            });
          }
        }
        if (error) {
          // TODO
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <span className="create-breakout-thread__header">
        <h4>
          Break out from {logo} {title}
        </h4>
      </span>
      <TypeToggle
        toggleType={toggleType}
        setToggleType={setToggleType}
        minimal={true}
      />
      <label className="">Break Out Logo</label>
      <EmojiInput
        emoji={formik?.values?.logo}
        setEmoji={emoji => {
          formik.setFieldValue('logo', emoji);
        }}
        setShowPicker={setEmoji}
        showPicker={emojiPicker}
      />
      <div className="create-thread__field pretty-form-group ">
        <FormikTextfield
          formik={formik}
          name={'title'}
          header="Break Out Title"
        />
      </div>
      <div className="create-thread__field pretty-form-group">
        <MultilineFormikTextfield
          formik={formik}
          name={'content'}
          header="Break Out Description"
          rows={4}
        />
      </div>
      <span className="pretty-form-group">
        <PermissionsInput
          formik={formik}
          name="permissions"
          header="Thread Members"
        />
      </span>
      <br />
      <p className="text-bold">
        Following members will be added to the break out thread
      </p>

      <RenderMemberContent
        thread={formik.values}
        button={{
          text: 'Remove',
          onClick: async item => {
            const {user_id} = item;
            const {permissions} = formik?.values ?? {};
            const filtered_permissions = permissions?.filter(
              user => user.user_id !== user_id,
            );
            formik.setFieldValue('permissions', filtered_permissions);
          },
        }}
      />
      <div className="create-thread__submit-container">
        <button
          className="create-thread__submit-button"
          onClick={formik.handleSubmit}
          disabled={formik.isSubmitting}>
          Create Breakout Thread
        </button>
      </div>
    </Modal>
  );
};

export const EditTaskModal = ({active, setActive, task}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <EditTaskContent
          active={active}
          setActive={setActive}
          setPopup={setPopup}
          task={task}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const EditTaskContent = ({active, setActive, setPopup, task}) => {
  const {updateContent} = useThreads();

  const formik = useFormik({
    initialValues: {
      mentions: task?.mentions ?? [],
      task_status: task?.task_status ?? 'not-started',
    },
    validationSchema: Yup.object({}),

    onSubmit: async (values, helpers) => {
      try {
        const {id} = task;
        const update = {
          id,
          ...values,
          updated: dateToTimestamp(),
        };

        const {success, error} = await updateContent(update);

        if (success) {
          helpers.resetForm();

          setActive(false);
          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <h4>Update Task</h4>
      <FormikSearchableDropDown
        formik={formik}
        header="Status"
        name="task_status"
        items={[
          {value: 'not-started', key: 'Not Started'},
          {value: 'in-progress', key: 'In Progress'},
          {value: 'complete', key: 'Complete'},
          {value: 'later', key: 'Later'},
        ]}
      />
      <div className="flex justify-center">
        <button onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
          Save
        </button>
      </div>
    </Modal>
  );
};
