import React, {useEffect, useState} from 'react';
import {IoMdArrowBack} from 'react-icons/io';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {ProfileImage} from '../../components/inputs/uploader';
import {ProfileItem} from '../../components/item-details/profile-item';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {DisplayField, DisplayLink} from '../../components/text/display-fields';
import {StatusIcon} from '../../components/widgets/widgets';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';
import useStringFormatter from '../../hooks/use-string-formatter';

const OrganizationDetail = ({}) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {loadProfiles} = useLoader();

  const {
    state: {organizations, profiles},
    getOrg,
  } = useAuth();

  const organization = organizations?.[id] ?? {};

  const {members} = organization || {};

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [inner, setInner] = useState([]);
  const [outer, setOuter] = useState([]);

  // FETCH INFO ON RELOAD
  useEffect(() => {
    const fetchOrgs = async () => {
      setLoading(true);
      if (organizations[id] === undefined) {
        await getOrg(id);
      }
      setLoading(false);
    };

    fetchOrgs();
  }, []);

  // FETCH MISSING PROFILES
  useEffect(() => {
    const fetchProfs = async () => {
      setLoading(true);
      await loadProfiles(members);
      setLoading(false);
    };
    fetchProfs();
  }, [members]);

  if (!id || !organization?.id) {
    return (
      <div className="page-container">
        {loading ? (
          <SpinningIndicator />
        ) : (
          <p>What you're looking for doesn't exist.</p>
        )}
      </div>
    );
  }

  return (
    <div className="page-container">
      <IoMdArrowBack
        className="clickable"
        onClick={() => {
          navigate(-1);
        }}
      />
      <Header id={id} />
      <div className="grid-container">
        <div className="grid-7">
          <div className="card">
            <AboutCard id={id} />
          </div>
        </div>
        <div className="grid-5">
          <Members id={id} />
          <Hierarchy id={id} />
        </div>
      </div>
    </div>
  );
};

const Members = ({id}) => {
  const {
    state: {organizations, profiles},
  } = useAuth();

  const [contacts, setContacts] = useState([]);

  const org = organizations?.[id] ?? {};
  const {members} = org;

  return (
    <div className="card">
      <h4>Team</h4>
      {members && members.length > 0 ? (
        members.map(id => {
          return <ProfileItem key={id} item={profiles[id] || {}} />;
        })
      ) : contacts.length > 0 ? (
        contacts.map(contact => {
          const {name, title, email, phone_number, college, url} = contact;
          return (
            <ProfileItem key={name} item={{name, email, position: title}} />
          );
        })
      ) : (
        <div className="flex-column justify-center">
          <p>No contact information.</p>
        </div>
      )}
    </div>
  );
};

const Header = ({id}) => {
  const {
    state: {organizations},
  } = useAuth();

  const organization = organizations[id];

  const {name, status, profile_image} = organization || {};

  return (
    <div>
      <div className="flex-row align-center justify-between margin-l16">
        <div className="flex-row align-center">
          {profile_image && (
            <ProfileImage data={profile_image} type="organization" />
          )}
          <h2 className=" margin-h16 ">{name}</h2>
          <StatusIcon status={status} />
        </div>
      </div>
    </div>
  );
};

const Hierarchy = ({id}) => {
  const {loadOrgs} = useLoader();
  const {
    state: {organizations},
  } = useAuth();

  const org = organizations?.[id] ?? {};
  const {children, parent} = org;

  useEffect(() => {
    if (children?.length) {
      loadOrgs(children);
    }
    if (parent) {
      loadOrgs([parent]);
    }
  }, [children, parent]);

  if (!children?.length && !parent) {
    return null;
  }

  return (
    <div className="card">
      <h4>Related Orgs</h4>
      {parent && <RelatedOrg item={organizations[parent]} />}
      {!!children?.length &&
        children.map(child => {
          return <RelatedOrg key={child} item={organizations[child]} />;
        })}
    </div>
  );
};

const RelatedOrg = ({item}) => {
  const navigate = useNavigate();
  const {id, name, status, members} = item || {};

  return (
    <div
      className="profile-container"
      style={{}}
      onClick={() => {
        navigate(`/feed/organizations/detail/${id}`);
      }}>
      <div className="flex-row align-center">
        <p className="text-left text-bold">{name}</p>
        <StatusIcon status={status} />
      </div>
      <p className="text-left">Members: {members?.length ?? 0}</p>
    </div>
  );
};

const AboutCard = ({id}) => {
  const {capitalize, prettyArray} = useStringFormatter();

  const {
    state: {organizations},
  } = useAuth();

  const organization = organizations?.[id] ?? {};

  const {owner, type, website, address, bio} = organization;

  return (
    <div>
      <h4>About</h4>
      <DisplayField header="Description" content={bio} />
      <DisplayField header="Type" content={capitalize(type)} />
      <DisplayField header="Contact" content={owner} />
      <DisplayLink header="Website" link={website} />
      <DisplayField header="Address" content={address} />
    </div>
  );
};

export default OrganizationDetail;
