import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import '../../App.css';
import {identify, track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import tagsJSON from '../../assets/lists/tags.json';
import constants from '../../components/constants';
import {FormikArraySearchableDropDown} from '../../components/inputs/drop-down';
import {
  FormikTextfield,
  MultilineFormikTextfield,
} from '../../components/inputs/textfields';
import {ProfileImage} from '../../components/inputs/uploader';
import {AuthHeader} from '../../components/layout/layout';
import {TooltipNoShadow} from '../../components/tooltip/tooltip';
import {useAuth} from '../../hooks/use-auth';
import {useMounted} from '../../hooks/use-mounted';
import {useThreads} from '../../hooks/use-threads';

const WelcomeAccount = ({}) => {
  const navigate = useNavigate();
  const isMounted = useMounted();

  const {
    state: {id, profile, current_organization},
    updateProfile,
  } = useAuth();
  const {
    state: {threads},
    getThread,
    updateThread,
  } = useThreads();

  const [file, setFile] = useState(null);

  const mobile = isMobile || isTablet;
  const public_thread_id = process.env.REACT_APP_PUBLIC_THREAD;

  useEffect(() => {
    getThread(public_thread_id);
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: profile?.first_name ?? '',
      last_name: profile?.last_name ?? '',
      position: profile?.position ?? '',
      bio: profile?.bio ?? '',
      inner_tags: profile?.inner_tags ?? [],
    },
    validationSchema: Yup.object({
      first_name: Yup.string().trim().required('You must add a first name.'),
      last_name: Yup.string().trim().required('You must add a last name.'),
      position: Yup.string().trim(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {
          first_name,
          last_name,
          position,
          bio,
          phone,
          properties,
          inner_tags,
        } = values;

        if (!inner_tags.includes('opp_networking')) {
          inner_tags.push('opp_networking');
        }

        const status = 'complete';

        const update = {
          id,
          first_name,
          last_name,
          position,
          bio,
          phone,
          status,
          properties,
          inner_tags,
          post_created: dateToTimestamp(),
        };
        if (file) {
          update.profile_image = file;
        }

        const {success, error} = await updateProfile(update);

        if (success) {
          if (success) {
            formik.resetForm();
            // ADD TO PUBLIC THREAD
            const thread = threads?.[public_thread_id] ?? {};
            const permissions = thread.permissions ?? [];
            const members = thread.members ?? [];

            if (!members.includes(id)) {
              const now = dateToTimestamp();
              const updated_permissions = [
                ...permissions,
                {
                  user_id: id,
                  role: 'collaborator',
                  created: now,
                  updated: now,
                },
              ];
              const updated_members = updated_permissions.map(
                item => item.user_id,
              );

              await updateThread({
                id: public_thread_id, // @Charlie -> make sure this is the correct ID
                permissions: updated_permissions,
                members: updated_members,
              });
            }

            // if (current_organization) {
            navigate('/threads');
            // } else {
            // navigate('/find-organization');
            // }
          }

          if (error) {
          }

          // HANDLE TRACKING
          track('create_profile', update);
          const {organization_ids, createdAt = ''} = profile;
          identify(id, {
            $email: id,
            $first_name: first_name,
            $last_name: last_name,
            $created: createdAt,
            aws_sub: id,
            organization_ids,
            phone,
            position,
            bio,
            status,
          });
        }
        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        if (isMounted()) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  return (
    <>
      <div className="container">
        <br />
        <AuthHeader
          header="Let's start with a few details"
          subheader={
            'Click each section below to add your details. This will go live for other members to see.'
          }
          action={true}
        />
        <div className="flex align-center justify-center pretty-form-group">
          <form className={mobile ? 'mobile-form' : 'width-640'}>
            <div className="grid-container">
              <div className="flex justify-center align-center">
                <TooltipNoShadow text={'Click to add a profile picture'}>
                  <ImageUploader file={file} setFile={setFile} />
                </TooltipNoShadow>
              </div>
              <span className="grid-12 padding-top16" />

              <div className="grid-12">
                <FormikTextfield
                  formik={formik}
                  placeholder="First Name"
                  name="first_name"
                  type="text"
                />
              </div>
              <span className="grid-12 padding-top16" />
              <div className="grid-12">
                <FormikTextfield
                  formik={formik}
                  placeholder="Last Name"
                  name="last_name"
                  type="text"
                />
              </div>
              <span className="grid-12 padding-top16" />

              <div className="grid-12">
                <FormikTextfield
                  formik={formik}
                  placeholder="Job Title"
                  name="position"
                  type="text"
                />
              </div>
              <span className="grid-12 padding-top16" />

              <div className="grid-12">
                <MultilineFormikTextfield
                  formik={formik}
                  rows={6}
                  placeholder="Enter a short bio..."
                  name="bio"
                  type="text"
                />
              </div>
            </div>
            <br />
            <span className="pretty-form-group">
              <FormikArraySearchableDropDown
                formik={formik}
                header="A few topics you're interested in?"
                name="inner_tags"
                items={tagsJSON}
                mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
              />
            </span>
          </form>
        </div>
      </div>
      {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}
      <div className="button padding-top16">
        <button
          type="button"
          className="button-container"
          onClick={formik.handleSubmit}
          disabled={
            formik.isSubmitting ||
            !formik.values.first_name ||
            !formik.values.last_name ||
            !formik.values.position
          }>
          SAVE
        </button>
      </div>
    </>
  );
};

const ImageUploader = props => {
  const {file, setFile} = props;

  const types = constants.image_mime_types;
  const limit = constants.file_20mb;

  const {
    state: {profile},
  } = useAuth();

  const url = profile?.profile_image ?? null;

  const handleFileChange = e => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (limit && file.size > limit) {
      const converted = (limit / (1024 * 1024)).toFixed(2);
      alert(`File size exceeds the maximum limit of ${converted}MB`);
      // Optionally, you can clear the selected file from the input
      e.target.value = null;
      return;
    }

    if (!types?.includes(file.type) ?? false) {
      alert('Invalid file type');
      e.target.value = null;
      return;
    }

    if (setFile) {
      setFile(file);
    }
  };

  const handleEditClick = () => {
    // Trigger the file input click event
    const inputElement = document.getElementById('image_input');
    if (inputElement) {
      inputElement.click();
    }
  };

  return (
    <div>
      <input
        id="image_input"
        type="file"
        accept={'image/*'}
        onChange={handleFileChange}
        style={{display: 'none'}}
      />
      <ProfileImage
        data={file ? {url: URL.createObjectURL(file)} : url}
        onClick={handleEditClick}
        type={'user'}
        style={{height: '48px', width: '48px'}}
      />
    </div>
  );
};

export default WelcomeAccount;
