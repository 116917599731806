import React, {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {FaHouse} from 'react-icons/fa6';
import {MdLogout} from 'react-icons/md';
import {RxPaperPlane} from 'react-icons/rx';
import {TbDatabaseSearch, TbMailPlus, TbSun, TbSunOff} from 'react-icons/tb';
import QRCode from 'react-qr-code';
import {Outlet, useNavigate} from 'react-router-dom';
import '../../App.css';
import {useAuth} from '../../hooks/use-auth';
import useLogout from '../../hooks/use-logout';
import useSearchParameters from '../../hooks/use-search-parameters';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import {BadgeDot} from '../badge/badge';
import {LinkButton} from '../buttons/buttons';
import {
  PublicMindLogoDarkNoText,
  PublicMindLogoLightNoText,
} from '../images/logos';
import {DropDown} from '../inputs/drop-down';
import {ProfileImage} from '../inputs/uploader';
import {NetworkBackground} from '../loading/network-background';
import {InviteModal} from '../modal/invite-modal';
import {Modal} from '../modal/modal';
import {TooltipRight} from '../tooltip/tooltip';
import './styles.css';

export const PublicLayout = props => {
  const {children} = props;

  return (
    <div className="base-container align-center">
      <div className="public-layout">{children || <Outlet />}</div>
      <NetworkBackground />
    </div>
  );
};

export const AuthLayout = props => {
  const {children} = props;

  return (
    <div className="base-container auth-layout">
      <Sidebar disabled={true} />
      {children || <Outlet />}
      <NetworkBackground />
      <Footer />
    </div>
  );
};

export const FeedLayout = props => {
  const {children} = props;

  return (
    <>
      <div className="base-container">
        <Sidebar />
        <div className={'feed-layout'}>{children || <Outlet />}</div>
      </div>
    </>
  );
};

const Sidebar = ({disabled}) => {
  const navigate = useNavigate();
  const logout = useLogout();

  const {
    state: {id, profile, current_organization},
  } = useAuth();

  const [add, setAdd] = useState(false);
  const [darkMode, setDarkMode] = useState(
    window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches,
  );
  const [popup, setPopup] = useState({on: false, message: ''});

  const userAgent = navigator.userAgent.toLowerCase();
  const isMac = userAgent.includes('mac');
  const commandKey = isMac ? 'CMD' : 'CTRL';

  useEffect(() => {
    document.documentElement.setAttribute(
      'data-theme',
      darkMode ? 'dark' : 'light',
    );
  }, [darkMode]);

  useEffect(() => {
    const setThemeBasedOnSystemPreference = () => {
      setDarkMode(
        window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: dark)').matches,
      );
    };

    setThemeBasedOnSystemPreference();
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', setThemeBasedOnSystemPreference);

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', setThemeBasedOnSystemPreference);
    };
  }, []);

  const handleAction = action => () => {
    switch (action) {
      case 'messages':
        navigate('/messages');
        break;
      case 'feed':
        navigate('/feed');
        break;
      case 'invite':
        setAdd(!add);
        break;
      case 'darkMode':
        setDarkMode(!darkMode);
        break;
      case 'home':
        navigate('/threads');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleKeyDown = event => {
      const {metaKey, ctrlKey, altKey, shiftKey, key, target} = event;
      const isInputElement = ['INPUT', 'TEXTAREA', 'SELECT'].includes(
        target.tagName,
      );

      if ((metaKey || ctrlKey) && !altKey && !shiftKey && !isInputElement) {
        const actionMap = {
          // 'm': 'messages',
          s: 'feed',
          i: 'invite',
          // 'p': 'post',
          d: 'darkMode',
          h: 'home',
        };

        if (actionMap[key]) {
          event.preventDefault();
          handleAction(actionMap[key])();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [darkMode, add]);

  const buttons = [
    {
      onClick: handleAction('home'),
      icon: darkMode ? (
        <PublicMindLogoLightNoText />
      ) : (
        <PublicMindLogoDarkNoText />
      ),
      message: 'Go to Threads',
      commandMessage: `${commandKey} + h`,
      iconTitle: 'Threads',
      notifications: 0,
    },
    {
      onClick: handleAction('feed'),
      icon: <TbDatabaseSearch />,
      message: 'Search database',
      commandMessage: `${commandKey} + s`,
      iconTitle: 'Search',
      notifications: 0,
    },
    {
      onClick: handleAction('invite'),
      icon: <TbMailPlus />,
      message: 'Invite to PublicMind',
      commandMessage: `${commandKey} + i`,
      iconTitle: 'Invite',
      notifications: 0,
    },

    {
      onClick: handleAction('darkMode'),
      icon: darkMode ? <TbSunOff /> : <TbSun />,
      message: 'Toggle Darkmode',
      commandMessage: `${commandKey} + d`,
      iconTitle: 'Theme',
      notifications: 0,
    },
  ];

  if (disabled) return null;

  return (
    <>
      <div className="sidebar-column">
        <div className="layout-action-buttons">
          {buttons.map((button, i) => (
            <div
              key={i}
              className="layout-action-button"
              onClick={button.onClick}>
              <TooltipRight
                text={button.message}
                subtext={button.commandMessage}>
                <span className="flex-column align-center">
                  {button.icon}
                  <p className="text-secondary text-10 padding-top8">
                    {button.iconTitle}
                  </p>
                </span>
                <BadgeDot active={button.notifications} />
              </TooltipRight>
            </div>
          ))}
        </div>
        <div className="flex-column align-center">
          <div className="flex-column">
            <DropDown
              items={[
                {text: 'Profile', onClick: () => navigate('/settings/profile')},

                {
                  text: 'Organization',
                  onClick: () => {
                    if (current_organization) {
                      navigate('/settings/organization');
                    } else {
                      navigate('/find-organization');
                    }
                  },
                },
                {text: 'Log Out', onClick: logout},
                ...(process.env.NODE_ENV === 'development'
                  ? [
                      {
                        text: 'MASTER',
                        onClick: () => navigate('/settings/master'),
                      },
                    ]
                  : []),
                ...(process.env.NODE_ENV === 'development'
                  ? [
                      {
                        text: 'ADMIN',
                        onClick: () => navigate('/settings/admin'),
                      },
                    ]
                  : []),
              ]}>
              <div className="margin-8">
                <TooltipRight text="View Profile Settings">
                  <ProfileImage
                    data={profile?.profile_image}
                    onClick={() => {}}
                    style={{height: '24px', width: '24px'}}
                  />
                </TooltipRight>
              </div>
            </DropDown>
          </div>
        </div>
      </div>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
      <InviteModal active={add} setActive={setAdd} />
    </>
  );
};
export default Sidebar;

const Footer = () => {
  const navigate = useNavigate();
  const mobile = isMobile || isTablet;

  return (
    <div className={mobile ? '' : 'footer-container'}>
      <p className="footer-text text-secondary">
        © 2024 by PublicMind {process.env.REACT_APP_VERSION} <br />
      </p>
      <p
        className="text-10 text-secondary text-underline cursor-pointer"
        onClick={() => {
          navigate('/terms-of-service');
        }}>
        Terms of Service
      </p>
      <p
        className="text-10 text-secondary text-underline cursor-pointer"
        onClick={() => {
          navigate('/privacy-policy');
        }}>
        Privacy Policy
      </p>
    </div>
  );
};

export const AuthHeader = ({header, subheader, action}) => {
  const logout = useLogout();

  const mobile = isMobile;

  return (
    <div className="container">
      {action && (
        <LinkButton onClick={logout} className="top-left">
          Log Out
        </LinkButton>
      )}
      <div className="flex-column align-center">
        <div className={mobile ? 'width-80' : 'width-66'}>
          <br />
          <div className="flex-column align-center">
            <PublicMindLogoLightNoText />
            {header && <h3 className="padding-top8">{header}</h3>}
            {subheader && (
              <p className="text-secondary padding-bottom16">{subheader}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const MobileLayout = ({children}) => {
  const {createReferralLink} = useSearchParameters();
  const logout = useLogout();
  const navigate = useNavigate();

  const {
    state: {id, current_organization, profile},
  } = useAuth();

  const [share, setShare] = useState(false);
  const [link, setLink] = useState('');

  useEffect(() => {
    const link = createReferralLink();
    setLink(link);
  }, [id, current_organization]);

  return (
    <>
      <div className="base-container">
        <div
          className="flex-row justify-between align-center"
          style={{marginBottom: '32px'}}>
          <div className="flex-row align-center">
            <button
              className="button-container-mobile"
              onClick={() => {
                navigate('/home');
              }}>
              <FaHouse />
            </button>
            <button
              className="invite-external-button-mobile"
              onClick={() => {
                setShare(!share);
              }}>
              <RxPaperPlane />
            </button>
          </div>
          <LinkButton onClick={logout}>
            <MdLogout size={'24px'} />
          </LinkButton>
        </div>
        {children || <Outlet />}
        <Footer />
      </div>
      <Modal active={share} setActive={setShare}>
        <p className="text-bold">Invite Members to the platform</p>
        <br />
        <div
          style={{
            height: 'auto',
            margin: '0 auto',
            width: '60%',
          }}>
          <QRCode
            size={256}
            style={{height: 'auto', maxWidth: '100%', width: '100%'}}
            value={link}
            viewBox={`0 0 256 256`}
          />
        </div>
        <br />
      </Modal>
    </>
  );
};
