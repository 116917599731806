import React, {useCallback, useEffect, useRef} from 'react';
import {isMobile} from 'react-device-detect';
import './graphstyles.css';

const NODE_COUNT = isMobile ? 10 : 24;
const CONNECTION_DISTANCE = 150;

export const NetworkBackground = () => {
  const canvasRef = useRef(null);
  const nodesRef = useRef([]);
  const connectionsRef = useRef([]);
  const animationFrameRef = useRef();

  const hexToRgb = hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  const generateNetwork = useCallback(() => {
    nodesRef.current = Array.from({length: NODE_COUNT}, () => ({
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      vx: (Math.random() - 0.5) * 0.15,
      vy: (Math.random() - 0.5) * 0.15,
    }));
  }, []);

  const updateNodePositions = useCallback(() => {
    nodesRef.current.forEach(node => {
      node.x += node.vx;
      node.y += node.vy;

      if (node.x < 0 || node.x > window.innerWidth) node.vx *= -1;
      if (node.y < 0 || node.y > window.innerHeight) node.vy *= -1;
    });

    connectionsRef.current = [];
    for (let i = 0; i < nodesRef.current.length; i++) {
      for (let j = i + 1; j < nodesRef.current.length; j++) {
        const dx = nodesRef.current[i].x - nodesRef.current[j].x;
        const dy = nodesRef.current[i].y - nodesRef.current[j].y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < CONNECTION_DISTANCE) {
          connectionsRef.current.push({
            source: i,
            target: j,
            strength: 1 - distance / CONNECTION_DISTANCE,
          });
        }
      }
    }

    animationFrameRef.current = requestAnimationFrame(updateNodePositions);
  }, []);

  const renderNetwork = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');

    const getComputedStyleValue = property =>
      getComputedStyle(document.documentElement)
        .getPropertyValue(property)
        .trim();

    const secondaryColor = getComputedStyleValue('--primary-button');
    const nodeColor = getComputedStyleValue('--primary-button');
    const primaryColor = getComputedStyleValue('--primary-button');

    const primaryRgb = hexToRgb(primaryColor);
    const secondaryRgb = hexToRgb(secondaryColor);

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';
    connectionsRef.current.forEach(connection => {
      const source = nodesRef.current[connection.source];
      const target = nodesRef.current[connection.target];
      const gradient = ctx.createLinearGradient(
        source.x,
        source.y,
        target.x,
        target.y,
      );
      gradient.addColorStop(
        0,
        `rgba(${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}, ${connection.strength})`,
      );
      gradient.addColorStop(
        1,
        `rgba(${secondaryRgb.r}, ${secondaryRgb.g}, ${secondaryRgb.b}, 1)`,
      );

      ctx.beginPath();

      ctx.beginPath();
      ctx.moveTo(source.x, source.y);
      ctx.lineTo(target.x, target.y);
      ctx.strokeStyle = gradient;
      ctx.lineWidth = connection.strength * 4;
      ctx.stroke();
    });

    nodesRef.current.forEach(node => {
      ctx.beginPath();
      ctx.arc(node.x, node.y, 4, 0, 2 * Math.PI);
      ctx.fillStyle = nodeColor;
      ctx.fill();
      ctx.strokeStyle = nodeColor;
      ctx.stroke();
    });

    requestAnimationFrame(renderNetwork);
  }, []);

  useEffect(() => {
    generateNetwork();
    animationFrameRef.current = requestAnimationFrame(updateNodePositions);
    return () => cancelAnimationFrame(animationFrameRef.current);
  }, [generateNetwork, updateNodePositions]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return; // Check if canvas is null

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    animationFrameRef.current = requestAnimationFrame(renderNetwork);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, [renderNetwork]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const resizeCanvas = () => {
      const dpr = window.devicePixelRatio || 1;
      const rect = canvas.getBoundingClientRect();
      canvas.width = rect.width * dpr;
      canvas.height = rect.height * dpr;
      canvas.style.width = `${rect.width}px`;
      canvas.style.height = `${rect.height}px`;
      const ctx = canvas.getContext('2d');
      ctx.scale(dpr, dpr);
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    return () => window.removeEventListener('resize', resizeCanvas);
  }, []);

  return (
    <div className="ai-network-background-container">
      <canvas ref={canvasRef} className="ai-network-canvas" />
    </div>
  );
};
