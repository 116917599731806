import {useFormik} from 'formik';
import {useEffect, useState} from 'react';
import {FaArrowUpFromBracket} from 'react-icons/fa6';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import {sendPrioritiesToEmail} from '../../api/email';
import {useAuth} from '../../hooks/use-auth';
import {useMounted} from '../../hooks/use-mounted';
import useSearchParameters from '../../hooks/use-search-parameters';
import useStringFormatter from '../../hooks/use-string-formatter';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import {PrimaryButton} from '../buttons/buttons';
import EmailPreviewModal from '../email/email-preview';
import {FormikTextfield} from '../inputs/textfields';
import {SpinningIndicator} from '../loading/loading-indicator';
import {Modal} from './modal';

// USED TO SHARE PRIORITIES WITH OTHER USERS - PROFILE SETTINGS
export const SharePrioritiesModal = ({active, setActive, priorities, type}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <SharePrioritiesModalContent
          active={active}
          setActive={setActive}
          priorities={priorities}
          type={type}
          setPopup={setPopup}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const SharePrioritiesModalContent = ({
  active,
  setActive,
  priorities,
  type,
  setPopup,
}) => {
  const isMounted = useMounted();
  const {createReferralLink} = useSearchParameters();
  const {prettyTag} = useStringFormatter();

  const {
    state: {id, organizations, current_organization, profile},
  } = useAuth();

  const organization = organizations[current_organization] ?? {};
  const priority_type = type[0].toUpperCase() + type.slice(1);

  const [emailFields, setEmailFields] = useState(['email_0']);

  const formik = useFormik({
    initialValues: {
      email_0: '',
    },
    validationSchema: Yup.object().shape(
      emailFields.reduce((acc, field) => {
        acc[field] = Yup.string().email('Must be a valid email').max(255);

        return acc;
      }, {}),
    ),
    onSubmit: async (values, helpers) => {
      try {
        const emails = emailFields.map(field => values[field]);
        for (const email of emails) {
          if (email === undefined) continue;
          const {id: org_id} = organization;
          const base_user = {
            id: email,
            email,
          };

          const link = createReferralLink(email);
          priorities = priorities?.map(x => {
            return prettyTag(x);
          });

          const {success, error} = await sendPrioritiesToEmail(
            base_user,
            link,
            priorities,
            profile,
            type,
          );

          if (success) {
            setPopup({
              on: true,
              message: 'Shared to: ' + email,
            });
            helpers.resetForm();

            track('shared_priorities', {
              user: id,
              org_id,
              sent_to: email,
              priorities,
            });
            setActive(!active);
            // navigate('/feed');
          }

          if (error) {
            setPopup({
              on: true,
              message: 'Error sending post to: ' + email,
            });

            helpers.setStatus({success: false});
            helpers.setErrors({submit: error});
            helpers.setSubmitting(false);
            return;
          }
        }
      } catch (err) {
        if (isMounted()) {
          setPopup({
            on: true,
            message: 'Error. Please reach out to the PublicMind team',
          });
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  useEffect(() => {
    const lastEmailField = emailFields[emailFields.length - 1];
    if (formik.values[lastEmailField] && !formik.errors[lastEmailField]) {
      setEmailFields([...emailFields, `email_${emailFields.length}`]);
    }
  }, [formik.values, formik.errors]);

  return (
    <Modal active={active} setActive={setActive}>
      <div className="flex-column align-center padding-bottom16 margin-h16">
        <span className="update-container-special-add">
          <FaArrowUpFromBracket />
        </span>
        <h2 className="invite-modal-title">Share Your New {priority_type}</h2>
        <p className="invite-modal-description">
          This will allow non-members to join PublicMind.
        </p>
      </div>
      <div className="grid-container justify-center">
        {emailFields.map((field, index) => (
          <div key={index} className="grid-7 invite-modal-form-group">
            <FormikTextfield
              formik={formik}
              placeholder=""
              name={field}
              type="text"
              header={`Email ${index + 1} ${index === 0 ? '(Required)' : ''}`}
            />
          </div>
        ))}
      </div>

      {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}
      <div className="flex justify-center invite-modal-actions">
        {formik.isSubmitting ? (
          <SpinningIndicator />
        ) : (
          <PrimaryButton
            className="invite-external-button"
            type="submit"
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
            }}>
            EMAIL PRIORITIES
          </PrimaryButton>
        )}
      </div>
      <EmailPreviewModal
        emailType={'sharePriorities'}
        emailProps={{
          profile,
          tags: priorities?.map(x => {
            return prettyTag(x);
          }),
          link: '',
          type,
        }}
      />
    </Modal>
  );
};
