import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  RxCopy,
  RxLockClosed,
  RxLockOpen1,
  RxLockOpen2,
  RxPencil1,
  RxPerson,
} from 'react-icons/rx';
import ReactMarkdown from 'react-markdown';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import {track} from '../../api/analytics';
import {dateToTimestamp, timeSince} from '../../api/dates';
import ActionPopup from '../../components/action-feedback-popup/action-feedback-popup';
import {Badge} from '../../components/badge/badge';
import constants from '../../components/constants';
import CreateComment from '../../components/inputs/create-comment';
import {SortDropdown} from '../../components/inputs/drop-down';
import ReplyToComment from '../../components/inputs/reply-to-comment';
import {Textfield} from '../../components/inputs/textfields';
import {ProfileImage} from '../../components/inputs/uploader';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import MagicMarkdown from '../../components/markdown/magic-markdown';
import {
  EditThreadModal,
  EditThreadPermissionsModal,
} from '../../components/modal/thread-modals';
import {MemberActivityTooltip} from '../../components/tooltip/member-activity-tooltip';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import useCrypto from '../../hooks/use-crypto';
import useLoader from '../../hooks/use-loader';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import useUtilities from '../../hooks/use-utilities';
import {BreakoutComment} from './thread-comment';
import ThreadHierarchy from './thread-hierarchy';
import ThreadMessageGroup from './thread-message-group';
import ThreadInfoPopup from './ThreadInfoPopup';
import './ThreadStyles.css';

const CollapsibleContent = ({content}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      const {clientHeight, scrollHeight} = contentRef.current;
      setIsOverflowing(scrollHeight > clientHeight);
    }
  }, [content]);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className={`thread-content-wrapper ${isExpanded ? 'expanded' : ''}`}>
      <div
        ref={contentRef}
        className={`thread-content ${isExpanded ? 'expanded' : ''}`}>
        <MagicMarkdown content={content} />
      </div>
      {isOverflowing && (
        <button className="expand-collapse-button" onClick={toggleExpand}>
          {isExpanded ? 'Hide' : 'See more'}
        </button>
      )}
    </div>
  );
};

const ThreadDetail = ({id, focused_content, setFocusedContent, setCurrent}) => {
  if (!id) {
    return null;
  }

  return (
    <ThreadDetailContent
      id={id}
      focused_content={focused_content}
      setFocusedContent={setFocusedContent}
      setCurrent={setCurrent}
    />
  );
};

const ThreadDetailContent = ({
  id,
  focused_content,
  setFocusedContent,
  setCurrent,
}) => {
  const navigate = useNavigate();

  const {copyString} = useUtilities();
  const {threadActions} = useCollaborator();
  const {prettyName} = useStringFormatter();
  const {loadProfiles} = useLoader();

  const {
    state: {id: user_id, profiles},
  } = useAuth();
  const {
    state: {
      threads,
      content_ids,
      thread_content,
      thread_subs,
      thread_subs_other,
      thread_subs_loaded,
      thread_request_ids,
      threads_loaded,
      request_subscriptions,
    },
    getContents,
    getContent,
    updateThreadSubscription,
    getThreadSubscriptionsByThread,
    createThreadSubscription,
    getThreadRequests,
    getThread,
    attachThreadRequestListener,
  } = useThreads();

  const thread = threads?.[id] ?? {};
  const {parent} = thread;
  const sub = thread_subs?.[id] ?? null;

  const {
    title,
    content,
    updated,
    permissions,
    last_sender,
    last_content,
    type,
    security_level,
    ai_assisted,
    logo,
    members,
  } = thread;

  const {editable, writeable} = threadActions(thread);

  const message_ids = content_ids?.[id] ?? [];

  const [initialSub, setInitial] = useState({...(sub || {})});
  const [loading, setLoading] = useState(false);
  const [reply, setReply] = useState(null);
  const [curQuote, setCurQuote] = useState(null);
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const [edit, setEdit] = useState(false);
  const [sort, setSort] = useState({value: 'recent', label: 'Recent'});
  const [sortedContent, setContent] = useState([]);
  const [search, setSearch] = useState('');
  const [aiEnabled, setAiEnabled] = useState(ai_assisted || false);
  const [popup, setPopup] = useState({on: false, message: ''});
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
  const [activityStats, setActivityStats] = useState({
    lastHour: 0,
    today: 0,
    last24Hours: 0,
  });

  const [memberActivities, setMemberActivities] = useState([]);
  const [threadHierarchy, setThreadHierarchy] = useState([]);

  const fetchThreadHierarchy = useCallback(
    async currentId => {
      if (!threads_loaded) {
        return;
      }
      const hierarchy = [];
      let current = threads[currentId] || thread_content[currentId];

      while (current) {
        hierarchy.unshift(current);

        if (current.type === 'thread' && !current.parent) {
          // We've reached the top-level Thread
          break;
        }

        if (current.type === 'comment') {
          // If it's a ThreadContent, we need to find its parent Thread
          if (threads[current.thread_id]) {
            current = threads[current.thread_id];
          } else {
            try {
              await getThread(current.thread_id);
              current = threads[current.thread_id];
            } catch (error) {
              console.error('Error fetching parent Thread:', error);
              break;
            }
          }
        } else if (
          current.type === 'thread' ||
          current.type === 'sub-thread' ||
          current.type === 'direct'
        ) {
          if (thread_content[current.parent]) {
            current = thread_content[current.parent];
          } else {
            // If it's a Thread, we need to find its parent ThreadContent
            try {
              const {success} = await getContent(current.parent);
              if (success) {
                current = thread_content[current.parent];
              } else {
                console.error('Parent ThreadContent not found');
                break;
              }
            } catch (error) {
              console.error('Error fetching parent ThreadContent:', error);
              break;
            }
          }
        } else {
          console.error('Unknown item type:', current);
          break;
        }
      }

      setThreadHierarchy(hierarchy);
    },
    [threads, getThread, getContents, thread_content],
  );

  useEffect(() => {
    if (id) {
      setThreadHierarchy([]);
      fetchThreadHierarchy(id);
    }
  }, [id, threads_loaded]);

  const setQuote = (contentId, text) => {
    setCurQuote(text);
    setReply(contentId);
  };

  const current_requests = thread_request_ids[id] ?? [];

  // HANDLE SORTING AND FILTERING
  useEffect(() => {
    const first_content = Object.keys(message_ids)
      .filter(parent_id => {
        if (!search) {
          return true;
        }
        let contains = 0;
        const parent = thread_content?.[parent_id];
        const replies = message_ids[parent_id];
        if (parent?.content?.includes(search)) {
          contains += 1;
        }
        if (replies?.length) {
          replies?.forEach(reply_id => {
            const reply = thread_content?.[reply_id] ?? {};
            if (reply?.content?.includes(search)) {
              contains += 1;
            }
          });
        }
        return contains;
      })
      .sort((a, b) => {
        const a_content = thread_content?.[a] ?? {};
        const b_content = thread_content?.[b] ?? {};
        const a_priority = a_content?.priority ? 1 : 0;
        const b_priority = b_content?.priority ? 1 : 0;

        const findRelevance = i => {
          const i_content = thread_content?.[i] ?? {};
          const replies = message_ids[i];
          let sum = 0;
          const now = Date.now();

          // Add relevance based on creation time (newer = more relevant)
          const ageInDays = (now - i_content.created) / (1000 * 60 * 60 * 24);
          sum += Math.max(0, 10 - ageInDays); // Max 10 points for newest messages

          if (i_content.owner_id === user_id) {
            sum += 5;
          }

          if (replies?.length) {
            replies.forEach(reply_id => {
              const reply = thread_content?.[reply_id] ?? {};
              if (reply.owner_id === user_id) {
                sum += 3;
              }
              const replyAgeInDays =
                (now - reply.created) / (1000 * 60 * 60 * 24);
              sum += Math.max(0, 5 - replyAgeInDays); // Max 5 points for newest replies
            });
          }

          return sum;
        };

        const sortTime = () => {
          const a_created = a_content?.created;
          const b_created = b_content?.created;
          if (a_created === undefined && b_created === undefined) {
            return 0;
          }
          // If a_created is missing, b should come first
          if (a_created === undefined) {
            return 1;
          }
          // If b_created is missing, a should come first
          if (b_created === undefined) {
            return -1;
          }
          return b_content?.created - a_content.created;
        };

        const sortTasks = () => {
          if (a_content.type !== 'task' && b_content.type !== 'task') {
            return 0; // Neither are tasks, no change in order
          }
          if (a_content.type === 'task' && b_content.type !== 'task') {
            return -1; // a is a task, it should come first
          }
          if (a_content.type !== 'task' && b_content.type === 'task') {
            return 1; // b is a task, it should come first
          }
          // Both are tasks, sort by status then by creation time
          const statusOrder = ['not-started', 'in-progress', 'completed'];
          const a_status_index = statusOrder.indexOf(a_content.task_status);
          const b_status_index = statusOrder.indexOf(b_content.task_status);
          if (a_status_index !== b_status_index) {
            return a_status_index - b_status_index;
          }
          // If status is the same, sort by creation time (newer first)
          return b_content.created - a_content.created;
        };

        switch (sort.value) {
          case 'tasks':
            return sortTasks();
          case 'active':
            if (a_priority !== b_priority) {
              return b_priority - a_priority;
            }
            const a_relevance = findRelevance(a);
            const b_relevance = findRelevance(b);
            // If relevance scores are different, sort by relevance
            if (b_relevance !== a_relevance) {
              return b_relevance - a_relevance;
            }
            return sortTime();
          default:
            if (a_priority !== b_priority) {
              return b_priority - a_priority;
            }
            return sortTime();
        }
      });
    setContent(first_content);
  }, [content_ids?.[id], sort, search]);
  // LOAD IN CONTENT - SHOULD ALWAY BE PRE-LOADED NOW
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const query = {thread_id: id, filter: {status: {eq: 'active'}}};
      await getContents(query, id);
      setLoading(false);
    };

    const init = Object.keys(content_ids);
    if (id && init?.length && content_ids[id] === undefined) {
      load();
    }

    if (id && thread_request_ids[id] === undefined) {
      const query = {thread_id: id, filter: {status: {eq: 'requested'}}};
      getThreadRequests(query);
    }

    if (id && request_subscriptions[id] === undefined) {
      attachThreadRequestListener(
        {filter: {thread_id: {eq: id}, status: {eq: 'requested'}}},
        id,
      );
    }
  }, [id]);

  // FETCH ALL PROFILES IN THREAD
  useEffect(() => {
    const fetchProfs = async () => {
      setLoading(true);
      await loadProfiles(members);
      setLoading(false);
    };
    fetchProfs();
  }, [members]);

  useEffect(() => {
    if (!id || !thread_subs_loaded) {
      return;
    }

    // SAVE THE INITIAL SUB BEFORE UPDATING IT
    setInitial(sub);

    const new_content = !sub?.last_read || sub?.last_read < updated;

    // IF NOT ACTIVE OR NO NEW CONTENT TO HAVE SEEN IGNORE
    if (!new_content) {
      return;
    }

    if (sub) {
      const update = {id: sub.id, thread_id: id, last_read: dateToTimestamp()};

      updateThreadSubscription(update);
    } else {
      const created = {
        id: v4(),
        user_id,
        thread_id: id,
        status: null,
        last_read: dateToTimestamp(),
      };

      createThreadSubscription(created);
    }
  }, [id, thread_subs_loaded]);

  // IF THE LAST SENDER UPDATE THE INITIAL SUB VALUE
  useEffect(() => {
    if (last_sender === user_id) {
      setInitial(sub);
    }
  }, [last_sender, last_content, sub?.last_read]);

  useEffect(() => {
    const load = async () => {
      setLoadingSubscriptions(true);
      await getThreadSubscriptionsByThread(
        {
          filter: {thread_id: {eq: id}, user_id: {ne: user_id}},
        },
        id,
      );

      setLoadingSubscriptions(false);
    };
    if (id && thread_subs_other?.[id] === undefined) {
      load();
    }
  }, [id]);

  useEffect(() => {
    if (parent && thread_content[parent] === undefined) {
      getThread(parent);
    }
  }, [parent]);

  const calculateActivities = useMemo(() => {
    const subs = thread_subs_other?.[id] ?? [];

    if (!subs.length || !permissions) return null;

    const now = Date.now();
    const oneHourAgo = now - 60 * 60 * 1000;
    const startOfToday = new Date().setHours(0, 0, 0, 0);
    const twentyFourHoursAgo = now - 24 * 60 * 60 * 1000;

    let lastHourCount = 0;
    let todayCount = 0;
    let last24HoursCount = 0;

    const activities = subs
      .map(sub_id => {
        const sub = thread_subs?.[sub_id] ?? {};
        const {last_read, user_id} = sub;
        const lastActiveTimestamp = last_read || 0;
        const lastActive = lastActiveTimestamp * 1000; // Convert AWSTimestamp to milliseconds

        let activityStatus = 'inactive';

        if (lastActive > oneHourAgo) {
          lastHourCount++;
          activityStatus = 'lastHour';
        } else if (lastActive > startOfToday) {
          todayCount++;
          activityStatus = 'today';
        } else if (lastActive > twentyFourHoursAgo) {
          last24HoursCount++;
          activityStatus = 'last24Hours';
        }

        return {
          member: user_id,
          lastActive: lastActiveTimestamp,
          activityStatus,
        };
      })
      .filter(item => item.member !== user_id)
      .sort((a, b) => b.lastActive - a.lastActive);

    return {
      activities,
      stats: {
        lastHour: lastHourCount,
        today: todayCount,
        last24Hours: last24HoursCount,
      },
    };
  }, [id, permissions, thread_subs_other, user_id, thread_subs]);

  useEffect(() => {
    if (calculateActivities) {
      setActivityStats(calculateActivities.stats);
      setMemberActivities(calculateActivities.activities);
    }
  }, [calculateActivities]);

  const getActivityColor = useCallback(() => {
    if (activityStats.lastHour > 0) return 'green';
    if (activityStats.today > 0) return 'yellow';
    return 'red';
  }, [activityStats]);

  const getActiveCount = useCallback(() => {
    if (activityStats.lastHour > 0) return activityStats.lastHour;
    if (activityStats.today > 0) return activityStats.today;
    return activityStats.last24Hours;
  }, [activityStats]);

  return (
    <>
      <div className="thread-detail-sticky-header">
        <ThreadHierarchy
          hierarchy={threadHierarchy}
          setCurrent={setCurrent}
          setFocusedContent={setFocusedContent}
        />

        <div className="thread-header">
          <span className="flex-row align-center">
            <h2>
              {logo ? logo + ' ' : null}
              {title}
            </h2>
            {security_level === 'secure' || type === 'direct' ? (
              <RxLockClosed
                title="Secure Thread"
                className="security-icon-small secure"
                onClick={() => setIsInfoPopupOpen(true)}
              />
            ) : security_level === 'private' ? (
              <RxLockOpen1
                title="Private Thread"
                className="security-icon-small private"
                onClick={() => setIsInfoPopupOpen(true)}
              />
            ) : (
              <RxLockOpen2
                title="Public Thread"
                className="security-icon-small open"
                onClick={() => setIsInfoPopupOpen(true)}
              />
            )}
            <div className="thread-actions">
              <MemberActivityTooltip
                memberActivities={memberActivities}
                prettyName={member =>
                  prettyName(profiles?.[member] || {email: member})
                }
                timeSince={timeSince}>
                <div className="activity-indicator">
                  <span className={`activity-dot ${getActivityColor()}`}></span>
                  <span>{getActiveCount()}</span>
                </div>
              </MemberActivityTooltip>
              {editable && (
                <>
                  {(true || security_level === 'open') && type !== 'direct' && (
                    <button
                      title="Copy invite link"
                      className="thread-action-button"
                      onClick={() => {
                        const link = `${constants.root_url}/threads/invite/${id}`;
                        copyString(link);
                        setPopup({
                          on: true,
                          message: 'Invite link copied to clipboard',
                        });
                        track('thread_invite_link_copied_small', {
                          link,
                          user_id,
                        });
                      }}>
                      <RxCopy />
                    </button>
                  )}

                  {(type === 'thread' || type === 'sub-thread') && (
                    <button
                      title="Edit thread members"
                      className="thread-action-button"
                      disabled={!editable}
                      onClick={() => setEditPermissions(!editPermissions)}>
                      <RxPerson />
                    </button>
                  )}

                  {editable && (
                    <button
                      title="Edit thread details"
                      className="thread-action-button"
                      onClick={() => setEdit(!edit)}>
                      <RxPencil1 />
                    </button>
                  )}
                </>
              )}
            </div>
          </span>
          <div className="flex-row">
            <Badge count={editable ? current_requests?.length : 0}>
              <button
                title="Click to see thread information"
                className="thread-info-button  margin-h8"
                onClick={() => setIsInfoPopupOpen(true)}>
                About
              </button>
            </Badge>
            <Badge count={0}>
              <button
                title="Pin thread to top of thread list"
                className="thread-info-button "
                onClick={async () => {
                  const update = {
                    id: sub?.id,
                    thread_id: id,
                    priority: sub?.priority ? null : `${dateToTimestamp()}`,
                  };

                  await updateThreadSubscription(update);
                  if (sub?.priority) {
                    track('thread_unpinned', {sub: update, user_id});
                  } else {
                    track('thread_pinned', {sub: update, user_id});
                  }
                }}>
                {sub?.priority ? 'Unpin' : 'Pin'}
              </button>
            </Badge>
            {(true || security_level === 'open') && type !== 'direct' && (
              <Badge count={0}>
                <button
                  title="Copy invite link to share"
                  className="thread-info-button margin-h8"
                  onClick={() => {
                    const link = `${constants.root_url}/threads/invite/${id}`;
                    copyString(link);
                    setPopup({
                      on: true,
                      message: 'Invite link copied to clipboard',
                    });
                    track('thread_invite_link_copied_big', {link, user_id});
                  }}>
                  Invite Link
                </button>
              </Badge>
            )}
          </div>
        </div>
        <CollapsibleContent content={content} />

        {writeable && <CreateComment id={id} />}
        <div className="sort-section">
          <label>Sort by:</label>
          <SortDropdown
            currentSort={sort}
            onSortChange={newSort => setSort(newSort)}
          />
          <div className="margin-h8">
            <span className="search-section">
              <Textfield
                value={search}
                type="text"
                placeholder="Search..."
                onChange={e => setSearch(e.target.value)}
                header=""
              />
            </span>
          </div>
        </div>
      </div>
      <div className="thread-message-container">
        {loading && <SpinningIndicator />}{' '}
        {parent && (
          <BreakoutComment
            item={thread_content?.[parent] ?? {}}
            description={content}
            onReply={e => {
              e.stopPropagation();
              // onReply(prev => (prev ? null : contentId));
            }}
            // focused={contentId === focusedContent}
            onClick={() => {
              if (thread_content?.[parent]) {
                navigate(
                  `/threads/${thread_content?.[parent].thread_id}/${thread_content?.[parent]?.id}`,
                );
              }
            }}
            sub={sub}
            // setFocusedContent={setFocusedContent}
            viewOnly={true}
          />
        )}
        {sortedContent.map(content_id => (
          <ThreadMessageGroup
            key={content_id}
            contentId={content_id}
            messageIds={message_ids[content_id]?.sort((a, b) => {
              const a_content = thread_content?.[a] ?? {};
              const b_content = thread_content?.[b] ?? {};
              // OLDEST FIRST
              return a_content?.created - b_content.created;
            })}
            threadContent={thread_content}
            onReply={setReply}
            onQuote={setQuote}
            focusedContent={focused_content}
            sub={initialSub}
            setFocusedContent={setFocusedContent}
          />
        ))}
      </div>
      {writeable && reply && (
        <ReplyToComment
          id={id}
          parent={reply}
          onClose={() => {
            setReply(null);
            setCurQuote(null);
          }}
          isOpen={!!reply}
          quoteText={curQuote}
        />
      )}
      <ThreadInfoPopup
        isOpen={isInfoPopupOpen}
        onClose={() => setIsInfoPopupOpen(false)}
        files={[]} // You'll need to implement file fetching logic
        thread={thread}
        setCurrent={setCurrent}
      />
      <EditThreadModal active={edit} setActive={setEdit} thread={thread} />
      <EditThreadPermissionsModal
        active={editPermissions}
        setActive={setEditPermissions}
        thread={thread}
      />
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

export const ContentContent = ({item}) => {
  const navigate = useNavigate();
  const {
    state: {id, profiles},
  } = useAuth();
  const {encryptString} = useCrypto();

  const {content, parent, owner_id} = item;

  const owner = owner_id === id;
  const timeSinceString = timeSince(item?.updated);

  const user = profiles?.[owner_id] ?? {};
  const profile_image = user?.profile_image;
  const {first_name, last_name, position} = user;

  return (
    <div>
      <div className="content-header">
        <ProfileImage
          data={profile_image}
          style={{height: '32px', width: '32px'}}
          onClick={() => {
            const parsed = encryptString(owner_id);
            navigate(`/feed/profiles/detail/${parsed}`);
          }}
        />
        <div className="user-info">
          <span className="flex-column">
            <span className="flex-row">
              <h3 className="user-name">{`${first_name} ${last_name}`}</h3>

              <p className="content-time">{timeSinceString}</p>
            </span>

            <p className="user-position">{owner ? 'Thread Owner' : ''}</p>
          </span>
        </div>
      </div>
      <div className="content-body">
        <ReactMarkdown children={content} />
      </div>
    </div>
  );
};

export default ThreadDetail;
